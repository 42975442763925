import React, { useState } from 'react';
import * as xlsx from 'xlsx';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PptxGenJS from 'pptxgenjs';
import { useNotifications } from '../../../hooks/useNotifications';
import './convert.css';

const TableComponent = ({ headers, selectedHeaders, data, selectedRows }) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <table style={{ borderRadius: '10px', fontSize: '13px' }}>
        <thead>
          <tr className="heading-row">
            {headers?.map((item, index) => {
              if (selectedHeaders.includes(item)) {
                return (
                  <th className="heading" key={index}>
                    {item}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {data[0] &&
            data?.map((item, index) => {
              if (selectedRows.includes(index + 1)) {
                return (
                  <tr key={index} style={{ margin: '2px' }}>
                    {item.map((item1, index1) => {
                      if (selectedHeaders.includes(Object.keys(item1)[0])) {
                        return (
                          <td className="data" key={index1}>
                            {item1[Object.keys(item1)[0]]}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              }
            })}
        </tbody>
      </table>
    </div>
  );
};

const getHeaders = (
  jsondata,
  setHeaders,
  setData,
  setSelectedHeaders,
  setSelectedRows,
  setRows,
  showNotifications,
  showTables,
  setShowTables
) => {
  const len = jsondata.length;
  let headers = [];
  let data = [];
  let rows = [];
  let idx = -1;
  let flag = false;
  for (let i = 0; i < len; i++) {
    if (jsondata[i]['__EMPTY'] === 'S.No') {
      flag = true;
      headers = Object.values(jsondata[i]);
      idx = i;
      break;
    }
  }
  if (!flag) {
    showNotifications(
      'error',
      'Please upload File with the format Specified in above steps',
      5000
    );
    return;
  }
  setHeaders(headers);
  setSelectedHeaders(headers);
  for (let i = idx + 1; i < len; i++) {
    const subData = [];
    const subD = Object.values(jsondata[i]);
    if (subD.length === headers.length) {
      for (let j = 0; j < headers.length; j++) {
        let obj = {};
        obj[headers[j]] = subD[j];
        subData.push(obj);
      }
      data.push(subData);
      rows.push(rows.length + 1);
    } else {
      break;
    }
  }
  setData(data);
  setSelectedRows(rows);
  setRows(rows);
  setShowTables(!showTables);
};

const ExcelToPPT = () => {
  const { showNotifications } = useNotifications();
  const [pptName, setPptName] = useState('');
  const [headers, setHeaders] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [showTables, setShowTables] = useState(false);
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedHeaders(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChange2 = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedRows(typeof value === 'string' ? value.split(',') : value);
  };

  const getStyles = (name, personName, theme) => {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  };

  const handleExportClick = () => {
    if (!pptName || pptName === '') {
      showNotifications('error', 'PPT Name cannot be empty', 5000);
      return;
    }
    const pptx = new PptxGenJS();
    pptx.tableToSlides('table_to_ppt', {
      x: 0,
      y: 0,
      slideMargin: 1,
      autoPageCharWeight: -1,
      addHeaderToEach: true,
      w: 10,
      addText: { text: pptName, options: { x: 1, y: 0.5, color: '176A67' } }
    });
    pptx.writeFile(pptName);
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const excelData = e.target.result;
        const workbook = xlsx.read(excelData, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        getHeaders(
          json,
          setHeaders,
          setData,
          setSelectedHeaders,
          setSelectedRows,
          setRows,
          showNotifications,
          showTables,
          setShowTables
        );
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    } else {
      return;
    }
  };
  return (
    <div>
      <h1
        style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '2rem' }}
      >
        Excel To PPT Conversion Tool
      </h1>
      <p>
        <span>Steps to convert Excel to PPT</span>
        <ul style={{ display: 'flex', flexDirection: 'column' }}>
          <l1>1. Choose any Excel File from your Computer.</l1>
          <l1>
            2. Ensure that your Excel Table must have a Column named `S.No`.
          </l1>
          <l1>
            3. After uploading Deselect unwanted headers and rows from the table
          </l1>
          <l1>4. Click on button titled `Convert To PPT & Download`.</l1>
        </ul>
      </p>
      <div style={{ marginTop: '4rem' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <input
            type="file"
            name="upload"
            id="upload"
            onChange={readUploadFile}
            accept=".xlsx"
          />
          {showTables && (
            <Button
              onClick={handleExportClick}
              variant="contained"
              style={{ backgroundColor: 'black' }}
            >
              Convert to PPT & Download
            </Button>
          )}
        </div>

        {showTables && (
          <div>
            <h1 style={{ fontSize: '16px', marginTop: '4rem' }}>
              Please Deselect Unwanted Headers
            </h1>
            <FormControl sx={{ m: 1, width: 'full' }}>
              <Select
                multiple
                displayEmpty
                value={selectedHeaders}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selectedHeaders) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedHeaders.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <MenuItem disabled value="">
                  <em>Please Select Columns</em>
                </MenuItem>
                {headers.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, selectedHeaders, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormControl sx={{ m: 1, width: 'full' }}>
              <h1 style={{ fontSize: '16px', marginTop: '1rem' }}>
                Please Deselect Unwanted Rows
              </h1>
              <Select
                multiple
                value={selectedRows}
                onChange={handleChange2}
                input={<OutlinedInput />}
                renderValue={(selectedRows) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedRows.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {rows.map((row) => (
                  <MenuItem
                    key={row}
                    value={row}
                    style={getStyles(row, selectedRows, theme)}
                  >
                    {row}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ margin: '10px 0px', width: '100%' }}>
              <TextField
                id="outlined-basic"
                label="PPT Name"
                variant="outlined"
                defaultValue=""
                value={pptName}
                onChange={(e) => setPptName(e.target.value)}
              />
            </div>
            <div id="table_to_ppt">
              <TableComponent
                headers={headers}
                selectedHeaders={selectedHeaders}
                data={data}
                selectedRows={selectedRows}
              />
            </div>
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExcelToPPT;
