import React, { useState } from 'react';
import {
  IconButton,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  Fade,
  Paper
} from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Form } from '../../Form';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import { rightsFields } from '../../../constants/RightsFields';
import axios from 'axios';
import { useDataLayerValue } from '../../../contextAPI/DataLayer';
export const MasterTableBody = ({
  currHeaders,
  data,
  toggleUpdate,
  url,
  fields,
  options
}) => {
  // console.log("in master table body", data);
  const [openEdit, setOpenEdit] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [openRights, setOpenRights] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState('');
  const [active, setActive] = useState(true);
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [{ _id, fname, lname, email }] = useDataLayerValue();
  const handleOpen = (event, currId, isActive) => {
    setAnchorEl(event.currentTarget);
    setActive(isActive);
    setId(currId);
    setOpenOptions((prevOpenOptions) => !prevOpenOptions);
  };

  const handleDelete = async () => {
    try {
      const res = await request(() =>
        axios.post(
          url + `/delete/${id}`,
          {
            addedByID: _id,
            addedByName: `${fname} ${lname}`,
            addedByEmail: email
          },
          {
            headers: {
              employeeid: _id
            }
          }
        )
      );
      console.log(res);
      if (res.data.statusCode !== 200) {
        showNotifications(
          'error',
          'Unable to delete item due to some error',
          5000
        );
      } else {
        showNotifications('success', res.data.message, 5000);
        setOpenOptions(false);
        toggleUpdate();
      }
    } catch (error) {
      console.log('HERE');
      showNotifications(
        'error',
        'Unable to delete item due to some error',
        5000
      );
      console.log(error);
    }
  };

  const makeActive = async () => {
    try {
      const res = await request(() =>
        axios.patch(
          url + `/makeActive/${id}`,
          {},
          {
            headers: {
              employeeid: _id
            }
          }
        )
      );
      console.log(res);
      if (res.data.statusCode !== 200) {
        showNotifications(
          'error',
          'Unable to make Active item due to some error',
          5000
        );
      } else {
        showNotifications('success', res.data.message, 5000);
        setOpenOptions(false);
        toggleUpdate();
      }
    } catch (error) {
      console.log('HERE');
      showNotifications(
        'error',
        'Unable to delete item due to some error',
        5000
      );
      console.log(error);
    }
  };

  const handleEditOpen = async () => {
    const res = await request(() =>
      axios.get(url + `/status/${id}`, {
        headers: {
          employeeid: _id
        }
      })
    );
    if (res.data.statusCode !== 200) {
      showNotifications('error', 'Unable to Edit Item', 5000);
    } else {
      fields.map((field) => {
        if (field.name in res.data.body) {
          field.value = res.data.body[field.name];
        }
        return field;
      });
      setOpenEdit(true);
    }
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setOpenOptions(false);
    toggleUpdate();
  };

  const handleGiveRightsOpen = () => {
    setOpenRights(true);
  };

  const handleApprove = async () => {
    try {
      const res = await request(() =>
        axios.patch(
          url + `/${id}/approve`,
          {},
          {
            headers: {
              employeeid: _id
            }
          }
        )
      );
      console.log(res.data);
      if (res.data.statusCode !== 200) {
        showNotifications('error', res.data.message, 5000);
      } else {
        showNotifications('success', 'Timesheet successfully Approved', 5000);
        setOpenOptions(false);
        window.location.reload();
        // toggleUpdate();
      }
    } catch (error) {
      console.log('HERE');
      showNotifications('error', error, 5000);
      console.log(error);
    }
  };

  const handleDecline = async () => {
    try {
      const res = await request(() =>
        axios.patch(
          url + `/${id}/reject`,
          {},
          {
            headers: {
              employeeid: _id
            }
          }
        )
      );
      console.log(res.data);
      if (res.data.statusCode !== 200) {
        showNotifications('error', res.data.message, 5000);
      } else {
        showNotifications('success', 'Timesheet successfully Declined', 5000);
        setOpenOptions(false);
        // toggleUpdate();
        window.location.reload();
      }
    } catch (error) {
      console.log('HERE');
      showNotifications('error', error, 5000);
      console.log(error);
    }
  };

  const handleGiveRightsClose = () => {
    setOpenRights(false);
    setOpenOptions(false);
    toggleUpdate();
  };

  return (
    <>
      <TableBody>
        {data.body.map((row) => {
          return (
            <TableRow
              // onClick={() => {
              //     console.log("I was clicked: ", row);
              // }}
              key={row._id}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              {currHeaders.map((currHeader) => {
                return currHeader.key === '_id' ? (
                  <TableCell
                    onClick={() => {
                      console.log('Current Row: ', row);
                    }}
                    key={currHeader.key}
                    align="center"
                  >
                    <Link
                      to="/master/data"
                      state={{
                        vals: row,
                        editOption: false
                      }}
                    >
                      {typeof row[currHeader.key] === 'boolean'
                        ? row[currHeader.key]
                          ? 'Yes'
                          : 'No'
                        : row[currHeader.key]}
                    </Link>
                  </TableCell>
                ) : (
                  <TableCell key={currHeader.key} align="center">
                    {currHeader.key === 'isActive'
                      ? row[currHeader.key]
                        ? 'Active'
                        : 'Inactive'
                      : typeof row[currHeader.key] === 'boolean'
                      ? row[currHeader.key]
                        ? 'Yes'
                        : 'No'
                      : row[currHeader.key]}
                  </TableCell>
                );
              })}
              <TableCell>
                <IconButton
                  onClick={(event) => handleOpen(event, row._id, row.isActive)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Popper open={openOptions} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={3500}>
                      <Paper>
                        <List>
                          {'editOption' in options &&
                          active &&
                          options.editOption ? (
                            <ListItem disablePadding>
                              <ListItemButton onClick={handleEditOpen}>
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                              </ListItemButton>
                            </ListItem>
                          ) : null}
                          {'deleteOption' in options && options.deleteOption ? (
                            <ListItem disablePadding>
                              <ListItemButton onClick={handleDelete}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Delete'} />
                              </ListItemButton>
                            </ListItem>
                          ) : null}
                          {'makeInactiveOption' in options &&
                          active &&
                          options.makeInactiveOption ? (
                            <ListItem disablePadding>
                              <ListItemButton onClick={handleDelete}>
                                <ListItemIcon>
                                  <RemoveIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Make Inactive'} />
                              </ListItemButton>
                            </ListItem>
                          ) : null}
                          {'makeActiveOption' in options &&
                          !active &&
                          options.makeActiveOption ? (
                            <ListItem disablePadding>
                              <ListItemButton onClick={makeActive}>
                                <ListItemIcon>
                                  <AddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Make Employee Active" />
                              </ListItemButton>
                            </ListItem>
                          ) : null}
                          {'rightsOption' in options && options.rightsOption ? (
                            <ListItem disablePadding>
                              <ListItemButton onClick={handleGiveRightsOpen}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Give Rights" />
                              </ListItemButton>
                            </ListItem>
                          ) : null}
                          {'approveOption' in options &&
                          options.approveOption ? (
                            <ListItem disablePadding>
                              <ListItemButton onClick={handleApprove}>
                                <ListItemIcon>
                                  <AddTaskIcon />
                                </ListItemIcon>
                                <ListItemText primary="Approve" />
                              </ListItemButton>
                            </ListItem>
                          ) : null}
                          {'declineOption' in options &&
                          options.declineOption ? (
                            <ListItem disablePadding>
                              <ListItemButton onClick={handleDecline}>
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Decline" />
                              </ListItemButton>
                            </ListItem>
                          ) : null}
                        </List>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <Form
        title="Edit Details"
        url={url + `/update/${id}`}
        open={openEdit}
        handleClose={handleEditClose}
        fields={fields}
        type="patch"
        headers={{
          employeeid: _id,
          empname: `${fname} ${lname}`,
          empemail: email
        }}
      />
      <Form
        title="Give Rights"
        url={url + `/promote/${id}`}
        open={openRights}
        handleClose={handleGiveRightsClose}
        fields={rightsFields}
        type="patch"
        headers={{
          employeeid: _id,
          empname: `${fname} ${lname}`,
          empemail: email
        }}
      />
    </>
  );
};
