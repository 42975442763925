import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { country_list } from '../../constants/countryList';
import { currency_list } from '../../constants/currencyList';

export default function AddressForm({
  handleNext,
  clientDetails,
  handleChange
}) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Company Information
      </Typography>
      <Box component="form" onSubmit={handleNext} sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="zohoLinkID"
              name="zohoLinkID"
              label="Zoho Link ID"
              fullWidth
              autoComplete="zohoLinkID"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.zohoLinkID}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="CompanyName"
              name="name"
              label="Company Name"
              fullWidth
              autoComplete="CompanyName"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.name}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 150 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Risk Type*
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={clientDetails.riskType}
                onChange={handleChange}
                label="Risk Type"
                name="riskType"
              >
                <MenuItem value={'High Risk'}>High Risk</MenuItem>
                <MenuItem value={'Medium Risk'}>Medium Risk</MenuItem>
                <MenuItem value={'Low Risk'}>Low Risk</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 150 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Currency*
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="currency"
                value={clientDetails.currency}
                onChange={handleChange}
                label="Currency"
              >
                {currency_list.map((currency, index) => {
                  return (
                    <MenuItem key={index} value={currency.name}>
                      {currency.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 150 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Company Type*
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={clientDetails.type}
                onChange={handleChange}
                label="Company Type"
                name="type"
              >
                <MenuItem value={'Private'}>Private</MenuItem>
                <MenuItem value={'Public'}>Public</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              id="registeredDate"
              label="Registered Date"
              type="date"
              name="registeredDate"
              value={clientDetails.registeredDate}
              InputLabelProps={{
                shrink: true
              }}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address"
              label="Address"
              fullWidth
              autoComplete="shipping address-line1"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="City"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="state"
              name="state"
              label="State/Province/Region"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={clientDetails.state}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              label="Zip / Postal code"
              fullWidth
              autoComplete="shipping postal-code"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.zip}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 150 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Country*
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="country"
                value={clientDetails.country}
                onChange={handleChange}
                label="Country"
              >
                {country_list.map((country, index) => {
                  return (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="phone"
              name="phone"
              label="Phone Number"
              fullWidth
              autoComplete="phone"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              variant="standard"
              type="email"
              onChange={handleChange}
              value={clientDetails.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              id="companyDesc"
              name="companyDesc"
              label="About the Company"
              autoComplete="companyDesc"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.companyDesc}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              multiline
              fullWidth
              id="companySize"
              name="companySize"
              label="Company Size"
              autoComplete="companySize"
              variant="standard"
              onChange={handleChange}
              value={clientDetails.companySize}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="pan"
              name="PAN"
              label="PAN Number"
              fullWidth
              variant="standard"
              onChange={handleChange}
              value={clientDetails.PAN}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                // onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
              >
                {'Next'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
