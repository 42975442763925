import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material';
import {
  downloadVersionRequest,
  getAllPolicyTemplates
} from '../../../constants/requests';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import DeletePolicyModal from '../Components/DeletePolicyModal';
import VersionPolicyModal from '../Components/VersionPolicyModal';
import AddLinkIcon from '@mui/icons-material/AddLink';
import HelpIcon from '@mui/icons-material/Help';
import { Input } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const PolicyTemplates = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [selectPolicyId, setSelectedPolicyId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const filterData = () => {
    const filtered = tableData.filter((data) =>
      data.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const sortByType = (data) => {
    const genericItems = data.filter((item) => item.type === 'Generic');
    const nonGenericItems = data.filter((item) => item.type !== 'Generic');
    return [...genericItems, ...nonGenericItems];
  };

  const getPolicies = async () => {
    try {
      const response = await request(() => getAllPolicyTemplates());
      if (response?.data?.statusCode === 200) {
        let newTableData = response.data.body.map((data) => {
          return {
            version: `${data.wordDocumentID.versions.length}.0`,
            title: data.name,
            _id: data._id,
            type: data.type
          };
        });

        newTableData = sortByType(newTableData);
        setTableData(newTableData);
        setFilteredData(newTableData);
        setAllTableData(response.data.body);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleDownloadWordVersion = async (idx, versionNo) => {
    try {
      const policyTemplate = allTableData.filter((data) => {
        return data._id === idx;
      });
      const id = policyTemplate[0].wordDocumentID._id;
      const versionId =
        policyTemplate[0].wordDocumentID.versions[versionNo].versionId;
      const res = await request(() => downloadVersionRequest(id, versionId));
      if (res?.status === 200) {
        const signedUrl = res.data.body['signedUrl'];
        window.open(signedUrl, '_blank.docx');
        showNotifications('success', 'Downloaded successfully', 5000);
      } else {
        if (res.response.data.statusCode !== 200) {
          showNotifications('error', res.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    getPolicies();
    allTableData;
  }, []);

  useEffect(() => {
    filterData(); // Initial data filtering
  }, [searchQuery]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [openVersionModal, setOpenVersionModal] = useState(false);
  const handleOpenVersionModal = () => setOpenVersionModal(true);
  const handleCloseVersionModal = () => setOpenVersionModal(false);

  return (
    <React.Fragment>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Policy Templates
        </Typography>
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'primary',
            height: 50,
            marginTop: '3px',
            marginRight: '10px'
          }}
          variant="contained"
          onClick={() => {
            window.open(
              'https://drive.google.com/drive/folders/1V-x9H_GTNsqeIvkxkrByFntpjWawcHKx?usp=sharing'
            );
          }}
        >
          <HelpIcon />
        </Button>
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'primary',
            height: 50,
            marginTop: '3px',
            marginRight: '10px'
          }}
          variant="contained"
          onClick={() => {
            navigate('/scf');
          }}
        >
          <AddLinkIcon sx={{ marginRight: '8px' }} />
          SCF
        </Button>
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'primary',
            height: 50,
            marginTop: '3px',
            marginRight: '10px'
          }}
          variant="contained"
          onClick={() => {
            navigate('master');
          }}
        >
          Master
        </Button>

        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            height: 50,
            marginTop: '3px'
          }}
          variant="contained"
          onClick={() => {
            navigate('add');
          }}
        >
          Add Policy
        </Button>
      </Box>
      <Input
        type="text"
        placeholder="Search by Policy Name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        autoFocus="true"
        style={{
          width: '100%',
          border: '1px solid black',
          padding: '10px',
          margin: '10px 0 10px 0'
        }}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell key={`table-version-header`} align="left">
                Version
              </StyledTableCell>
              <StyledTableCell key={`table-policy-name-header`} align="left">
                Policy Name
              </StyledTableCell>
              <StyledTableCell key={`table-type-header`} align="left">
                Type
              </StyledTableCell>
              <StyledTableCell align="center">Options</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((data) => {
              return (
                <StyledTableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={`policy-row-${data._id}`}
                >
                  {Object.keys(data).map((key) => {
                    if (key !== '_id') {
                      return (
                        <StyledTableCell
                          key={`policy-row-cell-${Math.random(data._id)}`}
                          onClick={() => {
                            navigate(`fill/${data._id}`);
                          }}
                        >
                          {data[key]}
                        </StyledTableCell>
                      );
                    }
                  })}
                  <StyledTableCell align="center">
                    {
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '5px'
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{ marginRight: 2 }}
                          onClick={() => {
                            handleOpenVersionModal();
                            setSelectedPolicyId(data._id);
                          }}
                        >
                          Versions
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            marginRight: 2,
                            color: 'white',
                            background: 'black'
                          }}
                          onClick={() => navigate(`addControls/${data._id}`)}
                        >
                          Add Controls
                        </Button>
                        <DeleteIcon
                          sx={{ marginTop: 0.7 }}
                          color={
                            data['type'] === 'Generic' ? 'disabled' : 'inherit'
                          }
                          onClick={() => {
                            if (data['type'] === 'Generic') return;
                            handleOpenDeleteModal();
                            setSelectedPolicyId(data._id);
                          }}
                        />
                      </Box>
                    }
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <VersionPolicyModal
        openVersionModal={openVersionModal}
        handleCloseVersionModal={handleCloseVersionModal}
        selectPolicyId={selectPolicyId}
        handleDownloadWordVersion={handleDownloadWordVersion}
      />

      {/*Delete Policy Modal*/}
      <DeletePolicyModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        selectPolicyId={selectPolicyId}
        getPolicies={getPolicies}
      />
    </React.Fragment>
  );
};

export default PolicyTemplates;
