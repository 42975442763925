const getBackendURL = (env) => {
  switch (env) {
    case 'development':
      return 'http://localhost:5000';
    case 'staging':
      return 'https://api.staging-erm.aumyaa.com';
    case 'production':
      return 'https://api.rm.aumyaa.com';
    default:
      return 'http://localhost:5000';
  }
};

const getFlaskHelperURL = (env) => {
  switch (env) {
    case 'development':
      return 'http://localhost:5001';
    case 'staging':
      return 'https://api.staging-erm.aumyaa.com/helper';
    case 'production':
      return 'https://api.rm.aumyaa.com/helper';
    default:
      return 'http://localhost:5001';
  }
};

export const BACKEND_URL = getBackendURL(process.env.REACT_APP_ENV);
export const FLASK_HELPER_URL = getFlaskHelperURL(process.env.REACT_APP_ENV);
