import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  getEmailPolicyContent,
  updateEmailPolicyContent
} from '../../../constants/requests';
import GetTableCell from '../Components/GetTableCell';

const style = {
  width: '100%',
  bgcolor: 'white',
  borderRadius: '5px',
  maxHeight: '85vh',
  overflowY: 'auto',
  p: 4
};

const EmailMaster = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [policyContent, setPolicyContent] = useState({
    emailSubject: '',
    emailContent: ''
  });

  const getPolicyDetails = async () => {
    try {
      const response = await request(() => getEmailPolicyContent());
      if (response?.data?.statusCode === 200) {
        const newPolicyContent = { ...policyContent };
        Object.keys(newPolicyContent).map((key) => {
          newPolicyContent[key] =
            response.data.body[key] === undefined
              ? ''
              : response.data.body[key];
        });
        // console.log(newPolicyContent);
        setPolicyContent(newPolicyContent);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const updatePolicyDetails = async () => {
    try {
      //   console.log(policyContent);
      const response = await request(() =>
        updateEmailPolicyContent(policyContent)
      );
      if (response?.data?.statusCode === 200) {
        showNotifications('success', 'Save successful', 5000);
      } else {
        if (response.response.data.statusCode !== 200) {
          showNotifications('error', response.response.data.message, 5000);
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const policyContentChangeHandler = (rowNumber, heading, value) => {
    console.log(heading, value);
    const newPolicyContent = { ...policyContent };
    newPolicyContent[heading] = value;
    setPolicyContent(newPolicyContent);
  };

  useEffect(() => {
    getPolicyDetails();
  }, []);

  return (
    <Box>
      <Box sx={style}>
        <Box>
          {Object.keys(policyContent).map((heading) => {
            return (
              <Box
                key={Math.random()}
                sx={{
                  marginTop: heading === 'generic_introduction' ? '0px' : '10px'
                }}
              >
                <GetTableCell
                  key={Math.random()}
                  rowNumber={'1'}
                  fieldName={heading}
                  fieldType={'multiLineTextField'}
                  value={policyContent[heading]}
                  onChangeCB={policyContentChangeHandler}
                  isEditable={true}
                  minRowsTable={heading === 'emailSubject' ? 2 : 6}
                  maxRowsTable={8}
                />
              </Box>
            );
          })}
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '2px'
          }}
        >
          <Button
            variant="contained"
            style={{ marginTop: '10px', backgroundColor: 'black' }}
            onClick={updatePolicyDetails}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailMaster;
