import {
  GET_PROJECT_URL,
  GET_TIMESHEET_URL,
  EMPLOYEE_URL,
  CLIENT_URL,
  HOLIDAY_URL,
  GET_MONTH_HOLIDAYS_URL,
  ADD_HOLIDAY_URL,
  REMOVE_HOLIDAY_URL,
  RIGHTS_URL,
  PROFILES_URL,
  ROLES_URL,
  ENTITIES_URL,
  DEPARTMENTS_URL,
  ENGAGEMENTS_URL,
  UNDERSTANDING_URL,
  APPLICATION_URL,
  RCM_URL,
  DRL_TEMPLATE_URL,
  DRL_URL,
  DRL_LOGS_URL,
  WORKFLOW_HANDLER_URL,
  ACTIVE_WORKFLOW_HANDLER_URL,
  DRL_SAMPLES_URL,
  SERVICES_URL,
  FIELD_REFRENCE_URL,
  TEMPLATE_URL,
  DOCUMENT_URL,
  SERVICE_URL,
  POLICY_TEMPLATE_URL,
  GET_ALL_TAGS_URL,
  DOCUMENT_MANAGER_URL,
  POLICY_ENGAGEMENT_URL,
  POLICY_URL,
  POLICY_MASTER_URL,
  GENERIC_POLICY_MASTER_URL,
  EMAIL_POLICY_MASTER_URL,
  READ_MSG_FILE_URL,
  GAP_TRACKER_URL,
  SCF_CONTROLS_URL,
  SCF_GOVERNANCE_URL,
  ORG_TABLE_URL,
  OPEN_AI_URL,
  TRUST_SERVICE_CRITERIA_URL,
  TESTING_SHEET_CREATOR_URL,
  PDF_TO_EXCEL_URL,
  SOC_PDF_DOWNLOAD,
  SOC_REPORT_SAVE_URL
} from './urls';
import axios from 'axios';

// The following interceptor parses 'application/json' response when responseType is 'blob'
// It was added as a feature for document downloads
// It comes into play when the responseType is 'blob' but the server wants to send 'application/json'
// (instead of the generated file/blob) to specify any errors in document generation
// Source: https://github.com/axios/axios/issues/815
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  }
);

export const getProjectsRequest = () => axios.get(GET_PROJECT_URL);

export const getProjectByIdRequest = (projectId) =>
  axios.get(`${GET_PROJECT_URL}/${projectId}`);

export const getClientsRequest = () => axios.get(CLIENT_URL);

export const getEmployeesRequest = () => axios.get(EMPLOYEE_URL);

export const getEmployeeByIdRequest = (eId) =>
  axios.get(`${EMPLOYEE_URL}/status/${eId}`);

export const getRightsRequest = () => axios.get(RIGHTS_URL);

export const getProfilesRequest = () => axios.get(PROFILES_URL);

export const getRolesRequest = () => axios.get(ROLES_URL);

export const getEntitiesRequest = () => axios.get(ENTITIES_URL);

export const getDepartmentsRequest = () => axios.get(DEPARTMENTS_URL);

export const getHolidaysOfMonthRequest = (month, year) =>
  axios.get(GET_MONTH_HOLIDAYS_URL(month, year));

export const getProjectByNameRequest = (projectName) =>
  axios.get(`${GET_PROJECT_URL}/name/${projectName}`);

export const getAssignedProjectsByEmployeeId = (employeeid) =>
  axios.get(`${GET_PROJECT_URL}/get-assigned`, { headers: { employeeid } });

export const getTasksRequest = (projectName) =>
  axios.get(`${GET_PROJECT_URL}/name/${projectName}/tasks`);

export const getTimesheetsRequest = (projectName, taskName) =>
  axios.get(`${GET_TIMESHEET_URL}/project/${projectName}/task/${taskName}`);

export const getTimesheetsRequestByEmployee = (data) => {
  const config = {
    method: 'post',
    url: GET_TIMESHEET_URL,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };

  return axios(config);
};

export const getTimesheetApprovals = (id) => {
  return axios.get(`${GET_TIMESHEET_URL}/unapproved/${id}`);
};

export const createTimesheet = (data) => {
  const config = {
    method: 'post',
    url: GET_TIMESHEET_URL + '/add',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  };

  return axios(config);
};

export const bulkApproveTimesheets = (obj) => {
  const config = {
    method: 'patch',
    url: GET_TIMESHEET_URL + '/bulkApprove',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(obj)
  };

  return axios(config);
};

export const getHolidays = () => {
  return axios.get(`${HOLIDAY_URL}`);
};
export const addHolidayRequest = (obj, month, year) => {
  const config = {
    method: 'patch',
    url: ADD_HOLIDAY_URL(month, year),
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(obj)
  };
  return axios(config);
};

export const removeHolidayRequest = (obj, month, year) => {
  const config = {
    method: 'patch',
    url: REMOVE_HOLIDAY_URL(month, year),
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(obj)
  };
  return axios(config);
};

export const updateProfileRequest = (profileId, updates) => {
  const config = {
    method: 'patch',
    url: `${PROFILES_URL}/${profileId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(updates)
  };
  return axios(config);
};

export const updateRightRequest = (rightId, updates) => {
  const config = {
    method: 'patch',
    url: `${RIGHTS_URL}/${rightId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(updates)
  };
  return axios(config);
};

export const updateRoleRequest = (roleId, updates) => {
  const config = {
    method: 'patch',
    url: `${ROLES_URL}/${roleId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(updates)
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Services ------------------------------------
// ---------------------------------------------------------------------

// ---------------------------------------------------------------------
// ----------------------- Engagements ---------------------------------
// ---------------------------------------------------------------------

export const getEngagementsRequest = (employeeid) =>
  axios.get(ENGAGEMENTS_URL, { headers: { employeeid } });
export const getEngagementByIdRequest = (engagementId) =>
  axios.get(`${ENGAGEMENTS_URL}/${engagementId}`);
export const createEngagementRequest = (engagement) => {
  const config = {
    method: 'post',
    url: `${ENGAGEMENTS_URL}`,
    data: engagement
  };
  return axios(config);
};
export const getServicesRequest = () => axios.get(SERVICES_URL);
export const updateEngagementRequest = (
  engagementId,
  engagement,
  employeeid
) => {
  const config = {
    headers: {
      employeeid
    },
    method: 'patch',
    url: `${ENGAGEMENTS_URL}/${engagementId}`,
    data: engagement
  };
  return axios(config);
};

export const uploadLogoRequest = (engagementId, payload) => {
  const config = {
    method: 'post',
    url: `${ENGAGEMENTS_URL}/${engagementId}/upload`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

export const reuploadLogoRequest = (engagementId, payload) => {
  const config = {
    method: 'patch',
    url: `${ENGAGEMENTS_URL}/${engagementId}/reupload`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

export const deleteEngagementRequest = (engagementId) => {
  const config = {
    method: 'delete',
    url: `${ENGAGEMENTS_URL}/${engagementId}`
  };
  return axios(config);
};

export const downloadMLPRequest = (engagementId) => {
  const config = {
    method: 'get',
    url: `${ENGAGEMENTS_URL}/${engagementId}/download/mlp`,
    responseType: 'blob'
  };
  return axios(config);
};

export const downloadMazarFindingsRequest = (engagementId) => {
  const config = {
    method: 'get',
    url: `${ENGAGEMENTS_URL}/${engagementId}/download/mazar-findings`,
    responseType: 'blob'
  };
  return axios(config);
};

export const downloadAITRequest = (engagementId) => {
  const config = {
    method: 'get',
    url: `${ENGAGEMENTS_URL}/${engagementId}/download/ait`,
    responseType: 'blob'
  };
  return axios(config);
};

export const downloadMAMRequest = (engagementId) => {
  const config = {
    method: 'get',
    url: `${ENGAGEMENTS_URL}/${engagementId}/download/mam`,
    responseType: 'blob'
  };
  return axios(config);
};
export const downloadTestingSheet = (payload) => {
  const config = {
    method: 'post',
    url: `${TESTING_SHEET_CREATOR_URL}`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' },
    responseType: 'blob'
  };
  return axios(config);
};
export const downloadCoverLetterRequest = (engagementId) => {
  const config = {
    method: 'get',
    url: `${ENGAGEMENTS_URL}/${engagementId}/download/cover`,
    responseType: 'blob'
  };
  return axios(config);
};

export const getPagesByEngagementIdRequest = (engagementId) =>
  axios.get(`${ENGAGEMENTS_URL}/${engagementId}/pages`);

// ---------------------------------------------------------------------
// ----------------------- Understanding -------------------------------
// ---------------------------------------------------------------------

export const updateUnderstandingRequest = (understandingId, understanding) => {
  const config = {
    method: 'patch',
    url: `${UNDERSTANDING_URL}/${understandingId}`,
    data: understanding
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- ORGTABLE -------------------------------
// ---------------------------------------------------------------------
export const getOrgTableById = (orgTableId) =>
  axios.get(`${ORG_TABLE_URL}/${orgTableId}`);

export const createOrgTableRequest = () => {
  const config = {
    method: 'post',
    url: `${ORG_TABLE_URL}`
  };
  return axios(config);
};
export const updateOrgTableRequest = (orgTableId, payload) => {
  const config = {
    method: 'patch',
    url: `${ORG_TABLE_URL}/${orgTableId}`,
    data: payload
  };
  return axios(config);
};
export const deleteOrgTableRequest = (orgTableId) => {
  const config = {
    method: 'delete',
    url: `${ORG_TABLE_URL}/${orgTableId}`
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- TRUST SERVICE CRITERIA -------------------------------
// ---------------------------------------------------------------------
export const getTrustServiceCriteriaById = (trustServiceId) =>
  axios.get(`${TRUST_SERVICE_CRITERIA_URL}/${trustServiceId}`);

export const createTrustServiceCriteriaRequest = () => {
  const config = {
    method: 'post',
    url: `${TRUST_SERVICE_CRITERIA_URL}`
  };
  return axios(config);
};
export const updateTrustServiceCriteriaRequest = (trustServiceId, payload) => {
  const config = {
    method: 'patch',
    url: `${TRUST_SERVICE_CRITERIA_URL}/${trustServiceId}`,
    data: payload
  };
  return axios(config);
};
export const deleteTrustServiceCriteriaRequest = (trustServiceId) => {
  const config = {
    method: 'delete',
    url: `${TRUST_SERVICE_CRITERIA_URL}/${trustServiceId}`
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Application ---------------------------------
// ---------------------------------------------------------------------

export const createApplicationRequest = (application) => {
  const config = {
    method: 'post',
    url: `${APPLICATION_URL}`,
    data: application
  };
  return axios(config);
};
export const updateApplicationRequest = (applicationId, application) => {
  const config = {
    method: 'patch',
    url: `${APPLICATION_URL}/${applicationId}`,
    data: application
  };
  return axios(config);
};
export const deleteApplicationRequest = (applicationId) => {
  const config = {
    method: 'delete',
    url: `${APPLICATION_URL}/${applicationId}`
  };
  return axios(config);
};
export const getApplicationByIdRequest = (applicationId) =>
  axios.get(`${APPLICATION_URL}/${applicationId}`);

// ---------------------------------------------------------------------
// ----------------------- RCM -----------------------------------------
// ---------------------------------------------------------------------

export const getRCMByIdRequest = (rcmId) => axios.get(`${RCM_URL}/${rcmId}`);
export const createRCMRequest = (rcm) => {
  const config = {
    method: 'post',
    url: `${RCM_URL}`,
    data: rcm
  };
  console.log(rcm);
  return axios(config);
};
export const deleteRCMRequest = (rcmId) => {
  const config = {
    method: 'delete',
    url: `${RCM_URL}/${rcmId}`
  };
  return axios(config);
};
export const updateRCMRequest = (rcmId, rcm) => {
  const config = {
    method: 'patch',
    url: `${RCM_URL}/${rcmId}`,
    data: rcm
  };
  return axios(config);
};
export const downloadRCMRequest = (applicationId) => {
  const config = {
    method: 'get',
    url: `${RCM_URL}/${applicationId}/download/testing`,
    responseType: 'blob'
  };
  return axios(config);
};

export const downloadCSARequest = (applicationId) => {
  const config = {
    method: 'get',
    url: `${RCM_URL}/${applicationId}/download/csa`,
    responseType: 'blob'
  };
  return axios(config);
};

export const uploadRCMRequest = (payload) => {
  const config = {
    method: 'post',
    url: `${RCM_URL}/upload`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- DRL Templates -------------------------------
// ---------------------------------------------------------------------

export const getDRLTemplatesRequest = () => axios.get(DRL_TEMPLATE_URL);
export const getDRLTemplateByTemplateType = (templateType) =>
  axios.get(`${DRL_TEMPLATE_URL}/${templateType}`);

export const updateDRLTemplateRequest = (templateType, drl) => {
  const config = {
    method: 'patch',
    url: `${DRL_TEMPLATE_URL}/${templateType}`,
    data: drl
  };
  return axios(config);
};

export const getDRLTemplatesByServiceIdRequest = (serviceId) =>
  axios.get(`${SERVICE_URL}/${serviceId}/drltemplates`);

// ---------------------------------------------------------------------
// ----------------------- DRL -----------------------------------------
// ---------------------------------------------------------------------

export const createDRLRequest = (payload) => {
  const config = {
    method: 'post',
    url: `${DRL_URL}`,
    data: payload
  };
  return axios(config);
};

export const getDRLById = (drlId) => axios.get(`${DRL_URL}/${drlId}`);

export const updateDRLById = (drlId, payload) => {
  const config = {
    method: 'patch',
    url: `${DRL_URL}/${drlId}`,
    data: payload
  };
  return axios(config);
};

export const addDRLById = (payload) => {
  const config = {
    method: 'post',
    url: `${DRL_URL}/add-document`,
    data: payload
  };
  return axios(config);
};

export const deleteDRLRequest = (drlId) => {
  const config = {
    method: 'delete',
    url: `${DRL_URL}/${drlId}`
  };
  return axios(config);
};

export const downloadDRLRequest = (engagementId, applicationId) => {
  const config = {
    method: 'get',
    url: `${DRL_URL}/download/engagement/${engagementId}/application/${applicationId}`,
    responseType: 'blob'
  };
  return axios(config);
};

export const getDRLLogs = (drlId) =>
  axios.get(`${DRL_LOGS_URL}/get-logs/${drlId}`);

// ---------------------------------------------------------------------
// ----------------------- Workflow ------------------------------------
// ---------------------------------------------------------------------

export const getWorkflowHandlerByIdRequest = (workflowHandlerId) =>
  axios.get(`${WORKFLOW_HANDLER_URL}/${workflowHandlerId}`);

export const getActiveWorkflowHandlerByIdRequest = (workflowHandlerId) =>
  axios.get(`${ACTIVE_WORKFLOW_HANDLER_URL}/${workflowHandlerId}`);

// ---------------------------------------------------------------------
// ----------------------- DRL Sampling --------------------------------
// ---------------------------------------------------------------------

export const getSamplesByIdRequest = (docId) =>
  axios.get(`${DRL_SAMPLES_URL}/document/${docId}`);

export const getSamplesByDRL = (drlId) =>
  axios.get(`${DRL_URL}/${drlId}/samples`);

export const getSamplesBySampleId = (sampleId) =>
  axios.get(`${DRL_SAMPLES_URL}/${sampleId}`);

export const readMsgFile = (payload) => {
  const config = {
    method: 'post',
    url: `${READ_MSG_FILE_URL}`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

export const createSamples = (payload) => {
  const config = {
    method: 'post',
    url: `${DRL_SAMPLES_URL}`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

export const updateSamples = (sampleId, payload) => {
  const config = {
    method: 'patch',
    url: `${DRL_SAMPLES_URL}/${sampleId}`,
    data: payload
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Policy Template (Version Routes Included)----
// ---------------------------------------------------------------------

export const getAllPolicyTemplates = () => axios.get(POLICY_TEMPLATE_URL);

export const getAllVersionsOfPolicyTemplateById = (id) =>
  axios.get(`${POLICY_TEMPLATE_URL}/${id}/versions`);

export const getPolicyTemplateById = (id) =>
  axios.get(`${POLICY_TEMPLATE_URL}/${id}`);

export const getAllTags = (payload) => {
  const config = {
    method: 'post',
    url: `${GET_ALL_TAGS_URL}`,
    data: payload
  };
  return axios(config);
};

export const createNewPolicyTemplate = (payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_TEMPLATE_URL}`,
    data: payload
  };
  return axios(config);
};

export const createNewPolicyTemplateVersion = (id, payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_TEMPLATE_URL}/${id}/versions`,
    data: payload
  };
  return axios(config);
};

export const updatePolicyTemplateById = (id, payload) => {
  const config = {
    method: 'patch',
    url: `${POLICY_TEMPLATE_URL}/${id}`,
    data: payload
  };
  return axios(config);
};

export const deletePolicyTemplate = (policyId) => {
  const config = {
    method: 'delete',
    url: `${POLICY_TEMPLATE_URL}/${policyId}`
  };
  return axios(config);
};

export const reverPolicyTemplateVersion = (policyId, versionId, payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_TEMPLATE_URL}/${policyId}/versions/revert/${versionId}`,
    data: payload
  };
  return axios(config);
};

export const deletePolicyTemplateVersion = (policyId, versionId) => {
  const config = {
    method: 'delete',
    url: `${POLICY_TEMPLATE_URL}/${policyId}/versions/${versionId}`
  };
  return axios(config);
};

export const getJsonFromExcel = (payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_TEMPLATE_URL}/excel-to-json`,
    data: payload
  };
  return axios(config);
};

export const downloadWordFromUrl = (url) => {
  const config = {
    method: 'get',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    },
    responseType: 'blob',
    url
  };
  return axios(config);
};

export const downloadExcelToWord = (payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_TEMPLATE_URL}/json-to-word`,
    data: payload,
    responseType: 'blob'
  };
  return axios(config);
};

export const downloadExcelFromJson = (payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_TEMPLATE_URL}/download-excel`,
    data: payload,
    responseType: 'blob'
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Policy Engagement ---------------------------
// ---------------------------------------------------------------------

//update this route
export const addPolicyEngagement = (payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_ENGAGEMENT_URL}`,
    data: payload
  };
  return axios(config);
};

export const getPolicyEngagementById = (id) =>
  axios.get(`${POLICY_ENGAGEMENT_URL}/${id}`);

export const getAllPolicyEngagement = (employeeid) => {
  const config = {
    method: 'get',
    url: `${POLICY_ENGAGEMENT_URL}`,
    headers: {
      employeeid: employeeid
    }
  };
  return axios(config);
};

export const updatePolicyEngagementById = (id, payload) => {
  const config = {
    method: 'patch',
    url: `${POLICY_ENGAGEMENT_URL}/${id}`,
    data: payload
  };
  return axios(config);
};

export const deletePolicyEngagement = (engagementId) => {
  const config = {
    method: 'delete',
    url: `${POLICY_ENGAGEMENT_URL}/${engagementId}`
  };
  return axios(config);
};

export const downloadMasterExcelFromJson = (id) => {
  const config = {
    method: 'post',
    url: `${POLICY_ENGAGEMENT_URL}/generate-excel/${id}`,
    responseType: 'blob'
  };
  return axios(config);
};

export const addNewPoliciesToEngagement = (id, obj) => {
  const config = {
    method: 'post',
    url: `${POLICY_ENGAGEMENT_URL}/add-policies/${id}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(obj)
  };
  return axios(config);
};

export const getJsonFromMasterExcel = (id, payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_ENGAGEMENT_URL}/one-excel-to-json/${id}`,
    data: payload
  };
  return axios(config);
};

export const getPolicyEngagementPublishHistory = (id) =>
  axios.get(`${POLICY_ENGAGEMENT_URL}/${id}/publishHistory`);

export const addPolicyEngagementPublishHistory = (id, payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_ENGAGEMENT_URL}/${id}/publishHistory`,
    data: payload
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Policy --------------------------------------
// ---------------------------------------------------------------------

export const getEngagementPolicyById = (id) => axios.get(`${POLICY_URL}/${id}`);

export const downloadPolicyById = (id) => {
  const config = {
    method: 'post',
    url: `${POLICY_URL}/download-policy/${id}`,
    responseType: 'blob'
  };
  return axios(config);
};

export const deleteEngagementPolicy = (policyId) => {
  const config = {
    method: 'delete',
    url: `${POLICY_URL}/${policyId}`
  };
  return axios(config);
};

export const updateEngagementPolicyById_Table = (id, payload) => {
  const config = {
    method: 'patch',
    url: `${POLICY_URL}/${id}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: payload
  };
  return axios(config);
};

export const triggerPolicyApprovalWorkflow = (id, payload, employeeid) => {
  const config = {
    method: 'patch',
    url: `${POLICY_URL}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      employeeid: employeeid
    },
    data: payload
  };
  return axios(config);
};

export const getAllSCFGovernance = () =>
  axios.get(`${POLICY_URL}/scf/governance`);

export const getSCFGovernanceById = (id) =>
  axios.get(`${POLICY_URL}/scf/governance/${id}`);

export const getGenericContentById = (id) =>
  axios.get(`${POLICY_URL}/generic-content/${id}`);

// ---------------------------------------------------------------------
// ----------------------- Policy Master (2 types)----------------------
// ---------------------------------------------------------------------

// ---------------------------------------------------------------------
// ----------------------- Policy Industry Type (1st type) -------------
// ---------------------------------------------------------------------

export const getAllIndustry = () => axios.get(`${POLICY_MASTER_URL}/industry`);

export const getIndustryById = (Id) => {
  axios.get(`${POLICY_MASTER_URL}/industry/${Id}`);
};

export const createIndustry = (payload) => {
  const config = {
    method: 'POST',
    url: `${POLICY_MASTER_URL}/industry`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(payload)
  };
  return axios(config);
};
export const deleteIndustryById = (Id) => {
  const config = {
    method: 'delete',
    url: `${POLICY_MASTER_URL}/industry/${Id}`
  };
  return axios(config);
};

export const deleteSubIndustryByName = (Id, name) => {
  const config = {
    method: 'delete',
    url: `${POLICY_MASTER_URL}/industry/${Id}/?subIndustry=${name}`
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Policy Template Type (2nd type) -------------
// ---------------------------------------------------------------------

export const getAllTemplateType = () =>
  axios.get(`${POLICY_MASTER_URL}/templateType`);

export const getTemplateTypeById = (typeId) => {
  axios.get(`${POLICY_MASTER_URL}/templateType/${typeId}`);
};

export const createTemplateType = (payload) => {
  const config = {
    method: 'POST',
    url: `${POLICY_MASTER_URL}/templateType`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(payload)
  };
  return axios(config);
};

export const deleteTemplateType = (typeId) => {
  const config = {
    method: 'delete',
    url: `${POLICY_MASTER_URL}/templateType/${typeId}`
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Policy Scope  -------------
// ---------------------------------------------------------------------

export const getAllPolicyScope = () => axios.get(`${POLICY_MASTER_URL}/scope`);

export const getPolicyScopeById = (typeId) => {
  axios.get(`${POLICY_MASTER_URL}/scope/${typeId}`);
};

export const createPolicyScope = (payload) => {
  const config = {
    method: 'POST',
    url: `${POLICY_MASTER_URL}/scope`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(payload)
  };
  return axios(config);
};

export const deletePolicyScope = (typeId) => {
  const config = {
    method: 'delete',
    url: `${POLICY_MASTER_URL}/scope/${typeId}`
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Generic Policy Content  -------------
// ---------------------------------------------------------------------

export const getAllGenericPolicyMaster = () =>
  axios.get(`${GENERIC_POLICY_MASTER_URL}`);

export const getGenericPolicyMasterById = (typeId) => {
  return axios.get(`${GENERIC_POLICY_MASTER_URL}/${typeId}`);
};

export const createGenericPolicyMaster = (payload) => {
  const config = {
    method: 'POST',
    url: `${GENERIC_POLICY_MASTER_URL}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(payload)
  };
  return axios(config);
};

export const updateGenericPolicyMasterById = (typeId, payload) => {
  const config = {
    method: 'patch',
    url: `${GENERIC_POLICY_MASTER_URL}/${typeId}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: payload
  };
  return axios(config);
};

export const deleteGenericPolicyMaster = (typeId) => {
  const config = {
    method: 'delete',
    url: `${GENERIC_POLICY_MASTER_URL}/${typeId}`
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Email Policy Content  -------------
// ---------------------------------------------------------------------

export const getEmailPolicyContent = () =>
  axios.get(`${EMAIL_POLICY_MASTER_URL}`);

export const updateEmailPolicyContent = (payload) => {
  const config = {
    method: 'patch',
    url: `${EMAIL_POLICY_MASTER_URL}`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: payload
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Document Manager ----------------------------
// ---------------------------------------------------------------------

export const getDocumentVersionsById = (docId) =>
  axios.get(`${DOCUMENT_MANAGER_URL}/${docId}`);

export const downloadVersionRequest = (docId, versionId) => {
  const config = {
    method: 'get',
    params: { versionId: versionId },
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    },
    url: `${DOCUMENT_MANAGER_URL}/${docId}/versions`
  };
  return axios(config);
};

export const deleteVersionRequest = (docId, versionId) => {
  const config = {
    method: 'delete',
    url: `${DOCUMENT_MANAGER_URL}/${docId}/versions/${versionId}`
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Service Request -----------------------------
// ---------------------------------------------------------------------

export const createService = (payload) => {
  const config = {
    data: payload,
    method: 'post',
    url: `${SERVICE_URL}`
  };
  return axios(config);
};

export const getAllService = () => axios.get(`${SERVICE_URL}`);

export const getServiceById = (serviceId) =>
  axios.get(`${SERVICE_URL}/${serviceId}`);

export const deleteServiceRequest = (serviceId) => {
  const config = {
    method: 'delete',
    url: `${SERVICE_URL}/${serviceId}`
  };
  return axios(config);
};

export const updateService = (serviceId, payload) => {
  const config = {
    method: 'patch',
    url: `${SERVICE_URL}/${serviceId}`,
    data: payload
  };
  return axios(config);
};

export const getEngagementByServiceId = (serviceId) =>
  axios.get(`${SERVICE_URL}/${serviceId}/engagement`);

// Field Refrence Requests
export const createFieldRefrence = (payload) => {
  const config = {
    data: payload,
    method: 'post',
    url: `${FIELD_REFRENCE_URL}`
  };
  return axios(config);
};

export const getAllFieldRefrences = () => axios.get(`${FIELD_REFRENCE_URL}`);

export const getFieldRefrencesById = (fieldId) =>
  axios.get(`${FIELD_REFRENCE_URL}/${fieldId}`);

export const deleteFieldRefrenceRequest = (fieldId) => {
  const config = {
    method: 'delete',
    url: `${FIELD_REFRENCE_URL}/${fieldId}`
  };
  return axios(config);
};

export const updateFieldRefrenceRequest = (fieldId, payload) => {
  const config = {
    method: 'patch',
    url: `${FIELD_REFRENCE_URL}/${fieldId}`,
    data: payload
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Template Editor -----------------------------
// ---------------------------------------------------------------------

export const createTemplate = (payload) => {
  const config = {
    method: 'post',
    url: `${TEMPLATE_URL}`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

export const getAllTemplates = () => axios.get(`${TEMPLATE_URL}`);

export const getTemplateById = (templateId) =>
  axios.get(`${TEMPLATE_URL}/${templateId}`);

export const getDocumentsByTemplateId = (templateId) =>
  axios.get(`${TEMPLATE_URL}/${templateId}/documents`);

export const deleteTemplateRequest = (templateId) => {
  const config = {
    method: 'delete',
    url: `${TEMPLATE_URL}/${templateId}`
  };
  return axios(config);
};

export const updateTemplate = (templateId, payload) => {
  const config = {
    method: 'patch',
    url: `${TEMPLATE_URL}/${templateId}`,
    data: payload
  };
  return axios(config);
};

export const getTemplatesByServiceIdRequest = (serviceId) =>
  axios.get(`${SERVICE_URL}/${serviceId}/templates`);

export const reuploadTemplate = (templateId, payload) => {
  const config = {
    method: 'patch',
    url: `${TEMPLATE_URL}/${templateId}`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- Document For Templates ----------------------
// ---------------------------------------------------------------------

export const createDocument = (payload) => {
  const config = {
    method: 'post',
    url: `${DOCUMENT_URL}`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

export const reuploadDocument = (documentId, payload) => {
  const config = {
    method: 'patch',
    url: `${DOCUMENT_URL}/${documentId}`,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  return axios(config);
};

export const downloadDocument = (documentId, payload) => {
  const config = {
    method: 'post',
    url: `${DOCUMENT_URL}/${documentId}/download`,
    data: payload,
    responseType: 'blob'
  };
  return axios(config);
};

export const getAllDocuments = () => axios.get(`${DOCUMENT_URL}`);

export const getDocumentById = (documentId) =>
  axios.get(`${DOCUMENT_URL}/${documentId}`);

export const deleteDocumentRequest = (documentId) => {
  const config = {
    method: 'delete',
    url: `${DOCUMENT_URL}/${documentId}`
  };
  return axios(config);
};

export const updateDocument = (documentId, payload) => {
  const config = {
    method: 'patch',
    url: `${DOCUMENT_URL}/${documentId}`,
    data: payload
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- GAP Tracker -----------------------------
// ---------------------------------------------------------------------

export const createGapTracker = (payload) => {
  const config = {
    method: 'post',
    url: `${GAP_TRACKER_URL}`,
    data: payload
  };
  return axios(config);
};

export const getGapIdFromPolicyEngagementId = (policyEngagementId) =>
  axios.get(`${POLICY_ENGAGEMENT_URL}/${policyEngagementId}/gapTracker`);

export const getGapIdFromSOC2EngagementId = (engagementId) =>
  axios.get(`${ENGAGEMENTS_URL}/${engagementId}/gapTracker`);

export const getAllGapTrackerDocuments = () => axios.get(`${GAP_TRACKER_URL}`);

export const getGapTrackerDocumentById = (gapId) =>
  axios.get(`${GAP_TRACKER_URL}/${gapId}`);

export const updateGapTrackerByGapId = (payload, gapId) => {
  const config = {
    method: 'patch',
    url: `${GAP_TRACKER_URL}/${gapId}`,
    data: payload
  };
  return axios(config);
};

export const deleteGapTrackerDocumentRequest = (gapId) => {
  const config = {
    method: 'delete',
    url: `${GAP_TRACKER_URL}/${gapId}`
  };
  return axios(config);
};

export const updateGapTrackerDocumentRequest = (payload, docId) => {
  const config = {
    method: 'patch',
    url: `${GAP_TRACKER_URL}/${docId}`,
    data: payload
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- SCF Controls --------------------------------
// ---------------------------------------------------------------------

export const createControl = (payload) => {
  const config = {
    method: 'post',
    url: `${SCF_CONTROLS_URL}`,
    data: payload
  };
  return axios(config);
};

export const getAllControls = () => axios.get(`${SCF_CONTROLS_URL}`);

export const getControlById = (controlId) =>
  axios.get(`${SCF_CONTROLS_URL}/${controlId}`);

export const connectControlsToPolicyTemplate = (policyTemplateId, payload) => {
  const config = {
    method: 'post',
    url: `${POLICY_TEMPLATE_URL}/${policyTemplateId}/control`,
    data: payload
  };
  return axios(config);
};

export const getControlByPolicyTemplateId = (policyTemplateId) =>
  axios.get(`${POLICY_TEMPLATE_URL}/${policyTemplateId}/control`);

// ---------------------------------------------------------------------
// ----------------------- SCF Governance --------------------------------
// ---------------------------------------------------------------------

export const createGovernance = (payload) => {
  const config = {
    method: 'post',
    url: `${SCF_GOVERNANCE_URL}`,
    data: payload
  };
  return axios(config);
};

export const getAllGovernances = () => axios.get(`${SCF_GOVERNANCE_URL}`);

export const getControlsByGovernanceId = (govId) =>
  axios.get(`${SCF_GOVERNANCE_URL}/${govId}/control`);

export const getGovernanceById = (govId) =>
  axios.get(`${SCF_GOVERNANCE_URL}/${govId}`);

export const connectGovernaceToControls = (govId, payload) => {
  const config = {
    method: 'post',
    url: `${SCF_GOVERNANCE_URL}/${govId}/control`,
    data: payload
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- SPECIAL API --------------------------------
// ---------------------------------------------------------------------

export const generateSentence = (payload) => {
  const config = {
    method: 'post',
    url: `${OPEN_AI_URL}/generate`,
    data: payload
  };
  return axios(config);
};

export const getPdfToExcel = (payload) => {
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: `${PDF_TO_EXCEL_URL}`,
    data: payload
  };
  return axios(config);
};

export const getSocReport = (payload) => {
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    responseType: 'blob',
    url: `${SOC_PDF_DOWNLOAD}`,
    data: payload
  };
  return axios(config);
};

export const saveSocExcel = (payload) => {
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: `${SOC_REPORT_SAVE_URL}`,
    data: payload
  };
  return axios(config);
};

// ---------------------------------------------------------------------
// ----------------------- END -----------------------------------------
// ---------------------------------------------------------------------
