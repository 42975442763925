import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, MenuItem, Modal, TextField } from '@mui/material';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import {
  getAllTemplates,
  createRCMRequest,
  deleteRCMRequest,
  updateRCMRequest,
  getRCMByIdRequest,
  getEngagementByIdRequest,
  getApplicationByIdRequest,
  getTemplatesByServiceIdRequest
} from '../../../constants/requests';
import Navbar from '../common/Navbar';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import CustomGrid from '../common/CustomGrid';
import UploadDialog from '../common/uploadDialog';
import DeleteDialog from '../common/DeleteDialog';
import CreateEditDialog from '../common/CreateEditDialog';

export default function RCM() {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { engagementId, applicationId } = useParams();
  const { showNotifications } = useNotifications();
  const [templateNames, setTemplateNames] = useState([]);
  const [addRecordForm, setAddRecordForm] = useState([]);
  const [otherScreenFields, setOtherScreenFields] = useState([]);
  const [initialRCMState, setInitialRCMState] = useState({});
  const [RCMId, setRCMId] = useState(null);
  const [delRow, setDelRow] = useState(null);
  const [RCMData, setRCMData] = useState(null);
  const [POFData, setPOFData] = useState(null);
  const [serviceID, setServiceID] = useState(null);
  const [templateType, setTemplateType] = useState(null);
  const [Section3Data, setSection3Data] = useState(null);
  const [currentRCMTabName, setCurrentRCMTabName] = useState(null);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const handleOpenAddRecordModal = () => createAddRecordForm();
  const handleCloseAddRecordModal = () => setOpenAddRecordModal(false);
  const handleOpenUploadModal = () => setOpenUploadModal(true);
  const handleCloseUploadModal = () => setOpenUploadModal(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [openDeleteRecordModal, setOpenDeleteRecordModal] = useState(false);
  const handleOpenDeleteRecordModal = (row) => {
    setDelRow(row);
    setOpenDeleteRecordModal(true);
  };
  const handleCloseDeleteRecordModal = () => {
    setDelRow(null);
    setOpenDeleteRecordModal(false);
  };

  const createAddRecordForm = () => {
    const { meta, rows } = RCMData;
    const { RCM, fields } = meta;
    const recordForm = Object.keys(RCM).map((key) => {
      return {
        type: fields[key]['fieldType'],
        fieldName: key,
        fieldLabel: `Enter ${key}`,
        value: key === 'S.No' ? rows[currentRCMTabName].length + 1 : '',
        required: false,
        dropDownOptions: fields[key]?.['options']
      };
    });
    const otherForm = Object.keys(fields).map((key) => {
      return {
        type: fields[key]['fieldType'],
        fieldName: key,
        fieldLabel: `Enter ${key}`,
        value: '',
        required: false,
        dropDownOptions: fields[key]?.['options']
      };
    });
    setAddRecordForm(recordForm);
    setOtherScreenFields(otherForm);
    setOpenAddRecordModal(true);
  };

  const getRCMTemplates = async () => {
    try {
      const engagementResponse = await request(() =>
        getEngagementByIdRequest(engagementId)
      );
      const { serviceId } = engagementResponse.data.body;
      setServiceID(serviceId);

      const response = await request(() =>
        getTemplatesByServiceIdRequest(serviceId)
      );
      if (response.data.statusCode === 200) {
        const templateNames = response.data.body
          .filter((template) => template.templateGroup === 'RCM')
          .map((template) => {
            return {
              value: template.templateName,
              label: template.templateName.replaceAll('_', ' ')
            };
          });
        console.log(templateNames);
        setTemplateNames(templateNames);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const getInitialRCMForRevert = async (templateType) => {
    try {
      const res = await request(() => getAllTemplates());
      if (res.data.statusCode === 200) {
        const RCMTemplate = res.data.body.filter(
          (template) => template.templateName === templateType
        );
        setInitialRCMState(RCMTemplate[0].data['rows']);
      } else {
        showNotifications('error', 'Initial State Not Found', 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getRCM = async () => {
    try {
      let response = await request(() =>
        getApplicationByIdRequest(applicationId)
      );
      if (response.data.statusCode === 200) {
        const RCMId = response.data.body.RCMId?._id;

        if (!RCMId) {
          // RCM not created yet
          setRCMData(null);
          return;
        }

        response = await request(() => getRCMByIdRequest(RCMId));
        console.log('____________________');
        console.log(response.data);
        setCurrentRCMTabName(Object.keys(response.data.body.data.rows)[0]);
        setTemplateType(response.data.body.templateType);
        setSection3Data(response.data.body.Section3Data);
        setPOFData(response.data.body.POFdata);
        setRCMData(response.data.body.data);
        setRCMId(response.data.body._id);
        getInitialRCMForRevert(response.data.body.templateType);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        data: RCMData
      };
      let response = await request(() => updateRCMRequest(RCMId, payload));
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleUploadSave = async (rcm) => {
    try {
      const payload = {
        data: rcm
      };
      let response = await request(() => updateRCMRequest(RCMId, payload));
      if (response.data.statusCode === 200) {
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleAddRecord = async (newRecord) => {
    try {
      const screenData = {},
        otherScreenData = {};
      Object.keys(newRecord).map((record) => {
        screenData[record] = newRecord[record]['value'];
      });
      otherScreenFields.map((row) => {
        otherScreenData[row['fieldName']] = '';
      });

      const newData = { ...otherScreenData, ...screenData };

      const newRCMData = { ...RCMData };
      let newRows = newRCMData['rows'][currentRCMTabName];
      newRows = [...newRows, newData];
      newRCMData['rows'][currentRCMTabName] = newRows;
      const payload = {
        data: newRCMData
      };
      let response = await request(() => updateRCMRequest(RCMId, payload));
      if (response.data.statusCode === 200) {
        setRCMData(newRCMData);
        handleCloseAddRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleDeleteRecord = async () => {
    try {
      const filteredData = RCMData['rows'][currentRCMTabName].filter(
        (row) => JSON.stringify(row) !== JSON.stringify(delRow)
      );

      if (templateType === 'SOC2.0' || templateType === 'ISAE 3402') {
        const controlNo = delRow['Control No.'];

        for (const table of Object.keys(POFData.rows)) {
          for (const pof of POFData.rows[table]) {
            const index = pof['Link Controls'].indexOf(controlNo);
            if (index > -1 && pof['Link Controls'].length > 0) {
              pof['Link Controls'].splice(index, 1);
            }
          }
        }

        for (const table of Object.keys(Section3Data.rows)) {
          for (const sectionRow of Section3Data.rows[table]) {
            const index = sectionRow['Control No.'].indexOf(controlNo);
            if (index > -1 && sectionRow['Control No.'].length > 0) {
              sectionRow['Control No.'].splice(index, 1);
            }
          }
        }
      }

      const newRCMData = {
        meta: RCMData.meta,
        rows: { ...RCMData['rows'], [currentRCMTabName]: filteredData }
      };
      const payload = {
        data: newRCMData,
        POFdata: POFData,
        Section3Data: Section3Data
      };
      let response = await request(() => updateRCMRequest(RCMId, payload));
      if (response.data.statusCode === 200) {
        setRCMData(newRCMData);
        handleCloseDeleteRecordModal();
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleRevertBack = async (revRow) => {
    try {
      const newRCMData = { ...RCMData };
      const initialRow = initialRCMState[currentRCMTabName].filter(
        (rows) => rows['S.No'] === revRow['S.No']
      );
      newRCMData['rows'][currentRCMTabName] = newRCMData['rows'][
        currentRCMTabName
      ].map((rows) => {
        if (rows['S.No'] === revRow['S.No']) {
          return initialRow[0];
        }
        return rows;
      });

      const payload = { data: newRCMData };
      let response = await request(() => updateRCMRequest(RCMId, payload));
      if (response.data.statusCode === 200) {
        setRCMData(newRCMData);
        showNotifications('success', response.data.message, 5000);
      } else {
        showNotifications('error', response.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
      console.log(err);
    }
  };

  const handleChange = (newRow, oldRow, RCMTab) => {
    const newRCMRows = [...RCMData['rows'][RCMTab]];
    newRCMRows.forEach((element, index) => {
      if (JSON.stringify(oldRow) === JSON.stringify(element)) {
        newRCMRows[index] = newRow;
      }
    });
    const newRCMData = { ...RCMData };
    newRCMData['rows'][RCMTab] = newRCMRows;
    setRCMData(newRCMData);
  };

  const createRCMModalFormFields = [
    {
      type: 'dropDown',
      fieldName: 'templateName',
      fieldLabel: 'RCM Template Name',
      dropDownOptions: templateNames,
      value: '',
      required: true
    }
  ];

  useEffect(() => {
    getRCM();
  }, []);

  useEffect(() => {
    getRCMTemplates();
  }, [RCMId]);

  return (
    <>
      <Navbar
        shouldRefresh={RCMData}
        pageName="RCM"
        engagementId={engagementId}
        applicationId={applicationId}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '30px'
        }}
      >
        <Box style={{ display: 'flex', gap: '5px' }}>
          <Button
            sx={{ color: 'white', backgroundColor: 'black' }}
            variant="contained"
            onClick={handleOpenAddModal}
            disabled={RCMData !== null}
          >
            Add RCM
          </Button>
          {POFData !== null ? (
            <Button
              sx={{ color: 'white', backgroundColor: '#ff5722' }}
              variant="contained"
              disabled={RCMData === null}
              onClick={() => {
                navigate(
                  `/services/engagement/${engagementId}/application/${applicationId}/rcm/${RCMId}/pof`
                );
              }}
            >
              Connect Criteria
            </Button>
          ) : null}
          <Button
            variant="contained"
            onClick={handleOpenAddRecordModal}
            disabled={RCMData === null}
          >
            Add Record
          </Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={RCMData === null}
            onClick={handleOpenUploadModal}
          >
            Upload RCM
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleOpenDeleteModal}
            disabled={RCMData === null}
          >
            Delete RCM
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '30px',
          gap: '10px'
        }}
      >
        {RCMData && (
          <TextField
            disabled={RCMData === null}
            select
            label="Tab Name"
            value={currentRCMTabName}
            onChange={(e) => setCurrentRCMTabName(e.target.value)}
            style={{ minWidth: '200px' }}
            size="small"
            InputLabelProps={{ shrink: true }}
          >
            {RCMData &&
              Object.keys(RCMData.rows)?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        )}
        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          disabled={RCMData === null}
        >
          Save
        </Button>
      </Box>

      <TabContext value={currentRCMTabName}>
        {RCMData
          ? Object.keys(RCMData.rows).map((RCMTab) => {
              return (
                <TabPanel
                  key={`RCM Table: ${RCMTab}`}
                  value={RCMTab}
                  index={RCMTab}
                  sx={{ p: '0px' }}
                >
                  <CustomGrid
                    tabName={RCMTab}
                    revertCB={handleRevertBack}
                    rows={RCMData.rows[RCMTab]}
                    tableMeta={RCMData.meta.RCM}
                    handleChangeCB={handleChange}
                    fieldsMeta={RCMData.meta.fields}
                    deleteCB={handleOpenDeleteRecordModal}
                  />
                </TabPanel>
              );
            })
          : null}
      </TabContext>

      <Modal
        open={openAddModal}
        onClose={handleCloseAddModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={createRCMModalFormFields}
          onCloseCB={handleCloseAddModal}
          refreshPageContentCB={getRCM}
          entityName="RCM"
          createEntityRequest={createRCMRequest}
          additionalPayloadParameters={{
            applicationID: applicationId,
            serviceID: serviceID
          }}
        />
      </Modal>

      <Modal
        open={openAddRecordModal}
        onClose={handleCloseAddRecordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditDialog
          formData={addRecordForm}
          onCloseCB={handleCloseAddRecordModal}
          refreshPageContentCB={getRCM}
          entityName="Record"
          customUpdateCB={handleAddRecord}
        />
      </Modal>

      <Modal
        open={openUploadModal}
        onClose={handleCloseUploadModal}
        aria-labelledby="modal-upload-rcm"
        aria-describedby="modal-upload-rcm-description"
      >
        <UploadDialog
          name="UPLOAD RCM"
          onCloseCB={handleCloseUploadModal}
          rcmData={RCMData}
          handleUploadSave={handleUploadSave}
          refreshPageContentCB={getRCM}
        />
        {/* <Box>
          <TextField type="file">Upload</TextField>
        </Box> */}
      </Modal>

      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DeleteDialog
          entityName="RCM"
          deleteRequest={deleteRCMRequest}
          entityId={RCMId}
          onCloseCB={handleCloseDeleteModal}
          refreshPageContentCB={getRCM}
        />
      </Modal>

      <Modal
        open={openDeleteRecordModal}
        onClose={handleCloseDeleteRecordModal}
      >
        <DeleteDialog
          entityName={`Record ${delRow !== null && delRow['S.No']}`}
          customDeleteCB={handleDeleteRecord}
          onCloseCB={handleCloseDeleteRecordModal}
        />
      </Modal>
    </>
  );
}
