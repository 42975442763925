import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  InputBase,
  InputLabel,
  Typography,
  FormControl,
  NativeSelect
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.5)
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 18,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const ControlForm = ({
  formData,
  heading,
  fieldOptions,
  saveFields
  // viewCB
}) => {
  const fields = {};
  formData &&
    Object.keys(formData).map((key) => {
      return (fields[formData[key]] = '');
    });

  const [open, setOpen] = useState(false);
  const [inputFields, setInputFields] = useState([fields]);

  const addInputField = () => {
    setInputFields([...inputFields, fields]);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
    saveFields(list, heading);
  };
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          {open ? (
            <KeyboardArrowDownIcon
              style={{ marginTop: '5px' }}
              onClick={() => setOpen(!open)}
            />
          ) : (
            <KeyboardArrowRightIcon
              style={{ marginTop: '5px' }}
              onClick={() => setOpen(!open)}
            />
          )}
          <Typography variant="h5">{heading}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px'
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              sx={{ background: 'black', color: 'white', marginRight: '15px' }}
              variant="contained"
              onClick={addInputField}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Box>

      <Box>
        <Collapse in={open}>
          {inputFields.map((data, index) => {
            const [field1, field2] = Object.keys(data);

            return (
              <Box
                key={index}
                style={{
                  marginBottom: '25px',
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'space-around'
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    variant="standard"
                    shrink={true}
                    htmlFor="uncontrolled-native"
                  >
                    Select {field1}
                  </InputLabel>
                  <NativeSelect
                    fullWidth
                    value={inputFields[field1]}
                    onChange={(e) => handleChange(index, e)}
                    input={<BootstrapInput />}
                    inputProps={{
                      name: field1,
                      id: 'uncontrolled-native',
                      shrink: true
                    }}
                  >
                    {fieldOptions.length > 0 &&
                      fieldOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </NativeSelect>
                </FormControl>
                <TextareaAutosize
                  fullWidth
                  name={field2}
                  value={inputFields[field2]}
                  onChange={(e) => handleChange(index, e)}
                  placeholder={`Enter ${field2}`}
                  minRows={2}
                  maxRows={4}
                  style={{ width: '100%', marginTop: '20px' }}
                />
              </Box>
            );
          })}
        </Collapse>
      </Box>
    </>
  );
};

export default ControlForm;
