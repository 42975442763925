import React from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button } from '@mui/material';
import IndustryTable from './IndustryTable';
import PolicyTypeTable from './PolicyTypeTable';
import PolicyScope from './PolicyScope';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GenericPolicyContent from './GenericPolicyContent';
import EmailMaster from './EmailMaster';

const PolicyMaster = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button
          sx={{
            color: 'white',
            backgroundColor: 'black',
            paddingLeft: 1
          }}
          variant="contained"
          onClick={() => navigate(`/policy`)}
        >
          <ChevronLeftIcon />
          Back
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }} variant="h4">
          Policy Master
        </Typography>
      </Box>

      <Accordion
        sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', borderRadius: '10px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '30px' }}>
            Industries
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <IndustryTable />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', borderRadius: '10px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '30px' }}>
            Policy Template Types
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <PolicyTypeTable />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', borderRadius: '10px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '30px' }}>
            Policy Scope
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <PolicyScope />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', borderRadius: '10px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '30px' }}>
            General Policy Master
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <GenericPolicyContent />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ backgroundColor: 'rgba(224, 224, 224, 1)', borderRadius: '10px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: '30px' }}>
            Email Content
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginBottom: '20px' }}>
          <EmailMaster />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default PolicyMaster;
