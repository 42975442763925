import React, { memo, useState } from 'react';
import {
  Select,
  MenuItem,
  TextField,
  FormControl,
  Checkbox,
  ListItemText
} from '@mui/material';
import moment from 'moment';
import TextareaAutosize from '@mui/base/TextareaAutosize';

export default memo(function GetTableCell({
  rowNumber,
  fieldName,
  fieldType,
  value,
  onChangeCB,
  isEditable,
  options,
  styles,
  isAdditional
}) {
  const [localValue, setLocalValue] = useState(value);

  switch (fieldType) {
    case 'textField':
      return (
        <TextField
          id={`rcm-cell-${rowNumber}-${fieldName}`}
          disabled={!isEditable}
          fullWidth
          value={localValue}
          label={`Enter ${fieldName}`}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={(e) =>
            onChangeCB(rowNumber, fieldName, e.target.value, isAdditional)
          }
          style={styles}
        />
      );
    case 'multiLineTextField':
      return (
        <TextareaAutosize
          id={`rcm-cell-${rowNumber}-${fieldName}`}
          disabled={!isEditable}
          value={localValue}
          placeholder={`Enter ${fieldName}`}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={(e) => {
            onChangeCB(rowNumber, fieldName, e.target.value, isAdditional);
          }}
          minRows={3}
          maxRows={4}
          style={{ ...styles, width: '100%' }}
        />
      );

    case 'date':
      return (
        <TextField
          fullWidth
          align="left"
          name={fieldName}
          disabled={!isEditable}
          type="date"
          value={moment(localValue).format('yyyy-MM-DD')}
          onChange={(e) => setLocalValue(e.target.value)}
          label={`Enter ${fieldName}`}
          InputLabelProps={{
            shrink: true
          }}
          onBlur={(e) =>
            onChangeCB(rowNumber, fieldName, e.target.value, isAdditional)
          }
        />
      );

    case 'dropDown':
      return (
        <TextField
          id={`rcm-cell-${rowNumber}-${fieldName}`}
          disabled={!isEditable}
          select
          label="Choose"
          fullWidth
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={(e) =>
            onChangeCB(rowNumber, fieldName, e.target.value, isAdditional)
          }
          style={styles}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );

    case 'multiSelect':
      return (
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select
            multiple
            displayEmpty
            fullWidth
            disabled={!isEditable}
            name={fieldName}
            value={
              typeof localValue === 'string'
                ? localValue.split(',')
                : localValue
            }
            onChange={(e) => setLocalValue(e.target.value)}
            onBlur={(e) =>
              onChangeCB(
                rowNumber,
                fieldName,
                e.target.value.filter((val) => val !== ''),
                isAdditional
              )
            }
            style={styles}
            renderValue={(selected) => {
              if (selected.toString() === '' || selected.length === 0) {
                return <em>Select</em>;
              }
              return selected.filter((val) => val !== '').join(',');
            }}
          >
            <MenuItem disabled value="">
              <em>Select</em>
            </MenuItem>
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={localValue.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    default:
      return null;
  }
});
