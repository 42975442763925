import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { Checkbox } from '@mui/material';
import * as XLSX from 'xlsx';
const TableComponent = ({
  outputTable,
  save,
  setSave,
  handleOpenSaveModal,
  tabValue
}) => {
  // const outputTable = {
  //     "S.No": [1, 2, 3, 4, 5],
  //     "Name": ["John", "Doe", "Jane", "Doe", "John"],
  //     "Age": [20, 30, 40, 50, 60],
  // };

  const [editTable, setEditTable] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));

  const inputStyles = {
    outline: 'none',
    height: '100%',
    fontSize: '14px',
    padding: '10px',
    border: '1px solid black',
    '&:focus': {
      border: '1px solid blue'
    },
    borderRadius: '5px'
  };

  const handleExportToExcel = () => {
    if (save) {
      handleOpenSaveModal();
    }
    let convertedData = [];
    for (let i = 0; i < rows.length; i++) {
      let obj = {};
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = rows[i][j];
      }
      convertedData.push(obj);
    }

    const worksheet = XLSX.utils.json_to_sheet(convertedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    XLSX.writeFile(workbook, 'DataSheet.xlsx');
  };

  useEffect(() => {
    let headers = [];
    let verticalRows = [];
    for (let key in outputTable) {
      headers.push(key);
      verticalRows.push(outputTable[key]);
    }
    setHeaders(headers);
    let rows = [];
    for (let i = 0; i < verticalRows[0]?.length; i++) {
      let row = [];
      for (let j = 0; j < verticalRows.length; j++) {
        row.push(verticalRows[j][i]);
      }
      rows.push(row);
    }
    setRows(rows);
  }, [outputTable]);

  return (
    <div style={{ marginTop: '40px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'end',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          gap: 2
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setHeaders([...headers, 'New Column']);
            setRows(rows.map((row) => [...row, 'New Cell']));
          }}
        >
          Add Column
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setRows([...rows, Array(headers.length).fill('New Cell')]);
          }}
        >
          Add Row
        </Button>
        <EditIcon
          sx={{
            color: 'black',
            mr: 1,
            '&:hover': {
              color: 'blue',
              cursor: 'pointer'
            }
          }}
          onClick={() => setEditTable(!editTable)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell key={index} align="left">
                  {editTable ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        gap: 2
                      }}
                    >
                      <input
                        style={inputStyles}
                        value={headers[index]}
                        onChange={(e) => {
                          setHeaders(
                            headers.map((header2, index2) => {
                              if (index === index2) {
                                return e.target.value;
                              }
                              return header2;
                            })
                          );
                        }}
                      />
                      <DeleteIcon
                        sx={{
                          color: 'white',
                          mr: 1,
                          '&:hover': {
                            color: 'red',
                            cursor: 'pointer'
                          }
                        }}
                        onClick={() => {
                          setHeaders(
                            headers.filter(
                              (header2, index2) => index !== index2
                            )
                          );
                          setRows(
                            rows.map((row) =>
                              row.filter((cell, index2) => index !== index2)
                            )
                          );
                        }}
                      />
                    </Box>
                  ) : (
                    header
                  )}
                </StyledTableCell>
              ))}
              {editTable && (
                <StyledTableCell align="left">Delete</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows[0] &&
              rows.map((row, index) => (
                <StyledTableRow key={index}>
                  {row.map((cell, index2) => (
                    <StyledTableCell key={index2} align="left">
                      {editTable ? (
                        <input
                          style={inputStyles}
                          value={cell}
                          onChange={(e) => {
                            setRows(
                              rows.map((row2, index3) => {
                                if (index === index3) {
                                  return row2.map((cell2, index4) => {
                                    if (index2 === index4) {
                                      return e.target.value;
                                    }
                                    return cell2;
                                  });
                                }
                                return row2;
                              })
                            );
                          }}
                        />
                      ) : (
                        cell
                      )}
                    </StyledTableCell>
                  ))}
                  {editTable && (
                    <StyledTableCell align="left">
                      <DeleteIcon
                        sx={{
                          color: 'black',
                          mr: 1,
                          '&:hover': {
                            color: 'red',
                            cursor: 'pointer'
                          }
                        }}
                        onClick={() => {
                          setRows(
                            rows.filter((row2, index3) => index !== index3)
                          );
                        }}
                      />
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'end',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          gap: 2
        }}
      >
        {tabValue !== '1' && (
          <Box>
            <Checkbox
              checked={save}
              onChange={() => setSave((prev) => !prev)}
            />
            Do you want to save?
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleExportToExcel}
        >
          EXPORT TO EXCEL
        </Button>
      </Box>
    </div>
  );
};

export default TableComponent;
