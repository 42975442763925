import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox
} from '@mui/material';
import { Document, pdfjs, Page } from 'react-pdf';
import { getPdfToExcel, getSocReport } from '../../../constants/requests';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import TableComponent from './TableComponent';
import SaveModal from './SaveModal';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
// import moment from 'moment';
import FileDownload from 'js-file-download';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const stepsToConvert = [
  'Click on "Choose File" Button and select the PDF file that you want to convert.',
  'Click on "Convert" Button to convert the PDF file to Excel.',
  'Select the pages which you want to parse for tables',
  'Once the file is converted, click on "Download" button to download the converted file.',
  'Customize the tables data after parsing the PDF file.',
  'If you want to convert another file, click on "Reset" button to clear the input fields.'
];
const pageContainerStyle = {
  position: 'relative',
  width: '200px',
  height: '300px',
  overflow: 'hidden'
};
const pageStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};
const checkboxStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  margin: '10px'
};
const labelStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  margin: '10px'
};

const PDFToExcel = () => {
  const { request } = useLoading();
  const { showNotifications } = useNotifications();
  const [numPages, setNumPages] = useState(null);
  const [selectedPages, setSelectedPages] = useState([]);
  const [file, setFile] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [save, setSave] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [pageTabValue, setPageTabValue] = useState('1');
  const [companyName, setCompanyName] = useState('');
  const [reportFY, setReportFY] = useState('');
  const [selectedPageForSheetOne, setSelectedPageForSheetOne] = useState([]);
  const [selectedPageForSheetTwo, setSelectedPageForSheetTwo] = useState([]);
  const [selectedPageForSheetThree, setSelectedPageForSheetThree] = useState(
    []
  );
  const [sheetOneTableResponse, setSheetOneTableResponse] = useState([]);
  const [sheetTwoTableResponse, setSheetTwoTableResponse] = useState([]);
  const [sheetThreeTableResponse, setSheetThreeTableResponse] = useState([]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handlePageTabChange = (event, newValue) => {
    setPageTabValue(newValue);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const isPagesVisible = (tabValue, pageTabValue) => {
    if (tabValue === '1') {
      return fetchedData.length === 0;
    } else {
      if (pageTabValue === '1') {
        return sheetOneTableResponse.length === 0;
      } else if (pageTabValue === '2') {
        return sheetTwoTableResponse.length === 0;
      } else {
        return sheetThreeTableResponse.length === 0;
      }
    }
  };
  const handlePageSelection = (pageNumber) => {
    if (tabValue === '1') {
      const newSelectedPages = [...selectedPages];
      if (newSelectedPages.includes(pageNumber)) {
        const index = newSelectedPages.indexOf(pageNumber);
        newSelectedPages.splice(index, 1);
      } else {
        newSelectedPages.push(pageNumber);
      }
      setSelectedPages(newSelectedPages);
    } else {
      if (pageTabValue === '1') {
        const newSelectedPages = [...selectedPageForSheetOne];
        if (newSelectedPages.includes(pageNumber)) {
          const index = newSelectedPages.indexOf(pageNumber);
          newSelectedPages.splice(index, 1);
        } else {
          newSelectedPages.push(pageNumber);
        }
        setSelectedPageForSheetOne(newSelectedPages);
      } else if (pageTabValue === '2') {
        const newSelectedPages = [...selectedPageForSheetTwo];
        if (newSelectedPages.includes(pageNumber)) {
          const index = newSelectedPages.indexOf(pageNumber);
          newSelectedPages.splice(index, 1);
        } else {
          newSelectedPages.push(pageNumber);
        }
        setSelectedPageForSheetTwo(newSelectedPages);
      } else {
        const newSelectedPages = [...selectedPageForSheetThree];
        if (newSelectedPages.includes(pageNumber)) {
          const index = newSelectedPages.indexOf(pageNumber);
          newSelectedPages.splice(index, 1);
        } else {
          newSelectedPages.push(pageNumber);
        }
        setSelectedPageForSheetThree(newSelectedPages);
      }
    }
  };
  const handleOpenSaveModal = () => setOpenSaveModal(true);
  const handleCloseSaveModal = () => setOpenSaveModal(false);
  const getFetchedData = (tabValue, pageTabValue) => {
    if (tabValue === '1') {
      return fetchedData;
    } else {
      if (pageTabValue === '1') {
        return sheetOneTableResponse;
      } else if (pageTabValue === '2') {
        return sheetTwoTableResponse;
      } else {
        return sheetThreeTableResponse;
      }
    }
  };
  const handleSubmit = async () => {
    if (file === null)
      return showNotifications('error', 'Please select a file', 5000);
    try {
      const formData = new FormData();
      formData.append('pdfFile', file);
      console.log(selectedPages);
      if (tabValue === '1') {
        formData.append('pagesToBeExtracted', selectedPages);
      } else {
        if (pageTabValue === '1') {
          formData.append('pagesToBeExtracted', selectedPageForSheetOne);
        } else if (pageTabValue === '2') {
          formData.append('pagesToBeExtracted', selectedPageForSheetTwo);
        } else {
          formData.append('pagesToBeExtracted', selectedPageForSheetThree);
        }
      }
      // setFile(null);
      const response = await request(() => getPdfToExcel(formData));
      console.log(response.data);
      if (tabValue === '1') {
        setFetchedData(response.data);
      } else {
        if (pageTabValue === '1') {
          setSheetOneTableResponse(response.data);
        } else if (pageTabValue === '2') {
          setSheetTwoTableResponse(response.data);
        } else {
          setSheetThreeTableResponse(response.data);
        }
      }
      showNotifications('success', 'File converted successfully', 5000);
    } catch (error) {
      showNotifications('error', 'Error converting file', 5000);
      console.log(error);
    }
  };

  const parseAllSheetResponses = () => {
    let json_data = {
      "Sheet1": {},
      "Sheet2": {},
      "Sheet3": {}
    }

    const sheetOneData = sheetOneTableResponse;
    const sheetTwoData = sheetTwoTableResponse;
    const sheetThreeData = sheetThreeTableResponse;

    const sheetOneKeys = Object.keys(sheetOneData);
    const sheetTwoKeys = Object.keys(sheetTwoData);
    const sheetThreeKeys = Object.keys(sheetThreeData);

    for (let i = 0; i < sheetOneKeys.length; i++) {
      const key = sheetOneKeys[i];
      const value = sheetOneData[key];
      const data = value.slice(1);
      json_data["Sheet1"][`COL${i}`] = {
        "displayName": key,
        "data": data
      }
    }
    json_data["Sheet1"]["companyName"] = {
      "displayName": companyName,
      "data": []
    }
    json_data["Sheet1"]["reportFY"] = {
      "displayName": reportFY,
      "data": []
    }

    for (let i = 0; i < sheetTwoKeys.length; i++) {
      const key = sheetTwoKeys[i];
      const value = sheetTwoData[key];
      const data = value.slice(1);
      json_data["Sheet2"][`COL${i}`] = {
        "displayName": key,
        "data": data
      }
    }

    json_data["Sheet2"]["companyName"] = {
      "displayName": companyName,
      "data": []
    }
    json_data["Sheet2"]["reportFY"] = {
      "displayName": reportFY,
      "data": []
    }

    for (let i = 0; i < sheetThreeKeys.length; i++) {
      const key = sheetThreeKeys[i];
      const value = sheetThreeData[key];
      const data = value.slice(1);
      json_data["Sheet3"][`COL${i}`] = {
        "displayName": key,
        "data": data
      }
    }

    json_data["Sheet3"]["companyName"] = {
      "displayName": companyName,
      "data": []
    }
    json_data["Sheet3"]["reportFY"] = {
      "displayName": reportFY,
      "data": []
    }

    return json_data;

  }

  // const getFormattedDate = () => {
  //   return moment().format('D_MMM_YYYY_h_mm_ss_a');
  // };

  // const getExtension = (contentType) => {
  //   switch (contentType) {
  //     case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
  //       return 'xlsx';
  //     case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
  //       return 'docx';
  //     default:
  //       return 'xlsx';
  //   }
  // };



  const handleDownloadSoc = async () => {
    const formData = new FormData();
    
    formData.append('Sheet1', sheetOneTableResponse);
    formData.append('Sheet2', sheetTwoTableResponse);
    formData.append('Sheet3', sheetThreeTableResponse);
    const parsed_data = parseAllSheetResponses();
    console.log(parsed_data);

    const response = await request(() => getSocReport(parsed_data));
    console.log(response)
    if (response?.response?.data?.statusCode) {
      showNotifications(
        'error',
        response.response.data.message,
        5000
      );
    } else {
      FileDownload(
        response.data,
        `SOC_REPORT.xlsx`
      );
      showNotifications(
        'success',
        'Downloaded successfully',
        5000
      );
    }
  }


  const getSelectedPagesValue = (tabValue, pageTabValue) => {
    if (tabValue === '1') {
      return selectedPages.join(', ');
    } else {
      if (pageTabValue === '1') {
        return selectedPageForSheetOne.join(', ');
      } else if (pageTabValue === '2') {
        return selectedPageForSheetTwo.join(', ');
      } else {
        return selectedPageForSheetThree.join(', ');
      }
    }
  };
  const getIfPageChecked = (tabValue, pageTabValue, index) => {
    if (tabValue === '1') {
      return selectedPages.includes(index + 1);
    } else {
      if (pageTabValue === '1') {
        return selectedPageForSheetOne.includes(index + 1);
      } else if (pageTabValue === '2') {
        return selectedPageForSheetTwo.includes(index + 1);
      } else {
        return selectedPageForSheetThree.includes(index + 1);
      }
    }
  };
  const getIfDisabled = (tabValue, pageTabValue) => {
    if (tabValue === '1') {
      return selectedPages.length === 0;
    } else {
      if (pageTabValue === '1') {
        return selectedPageForSheetOne.length === 0;
      } else if (pageTabValue === '2') {
        return selectedPageForSheetTwo.length === 0;
      } else {
        return selectedPageForSheetThree.length === 0;
      }
    }
  };
  const handleReset = (tabValue, pageTabValue) => () => {
    if (tabValue === '1') {
      setSelectedPages([]);
    } else {
      if (pageTabValue === '1') {
        setSelectedPageForSheetOne([]);
      } else if (pageTabValue === '2') {
        setSelectedPageForSheetTwo([]);
      } else {
        setSelectedPageForSheetThree([]);
      }
    }
  };
  return (
    <>
      <Box style={{ flex: 1, marginLeft: 30 }}>
        <Typography variant="h3" component="h4" gutterBottom>
          PDF To Excel
        </Typography>
        <Typography variant="h4" gutterBottom>
          Steps to convert PDF to Excel:
        </Typography>
        {
          <ol>
            {stepsToConvert.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
        }
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue} sx={{ display: 'flex' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginBottom: 5,
                width: '50%'
              }}
            >
              <TabList onChange={handleTabChange} aria-label="PDF TO EXCEL">
                <Tab label="General Use" value="1" sx={{ flexGrow: 1 }} />
                <Tab label="SOC Report" value="2" sx={{ flexGrow: 1 }} />
              </TabList>
            </Box>
          </TabContext>
        </Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TextField
              id="outlined-basic"
              label="Choose File"
              variant="outlined"
              type="file"
              accept="application/pdf"
              focused
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Grid>
        </Grid>
        {tabValue === '2' && (
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={pageTabValue} sx={{ display: 'flex' }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  marginTop: 5,
                  width: '50%'
                }}
              >
                <TabList onChange={handlePageTabChange} aria-label="Sheets">
                  <Tab label="Sheet 1" value="1" sx={{ flexGrow: 1 }} />
                  <Tab label="Sheet 2" value="2" sx={{ flexGrow: 1 }} />
                  <Tab label="Sheet 3" value="3" sx={{ flexGrow: 1 }} />
                </TabList>
              </Box>
            </TabContext>
          </Box>
        )}
        <Grid container spacing={2} alignItems="center" marginTop={2}>
          <Grid item>
            <TextField
              label="Selected Pages"
              variant="outlined"
              focused
              value={getSelectedPagesValue(tabValue, pageTabValue)}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={getIfDisabled(tabValue, pageTabValue)}
            >
              Convert
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReset(tabValue, pageTabValue)}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <Box>
          {isPagesVisible(tabValue, pageTabValue) && (
            <Box>
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Grid container spacing={2}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                      <Typography syle={labelStyle}>Page {index + 1}</Typography>
                      <div style={pageContainerStyle}>
                        <Page
                          pageNumber={index + 1}
                          renderTextLayer={false}
                          width={200}
                          style={pageStyles}
                          onClick={() => handlePageSelection(index + 1)}
                        />
                        <Checkbox
                          style={checkboxStyle}
                          checked={getIfPageChecked(
                            tabValue,
                            pageTabValue,
                            index
                          )}
                          onChange={() => handlePageSelection(index + 1)}
                          label={`Page ${index + 1}`}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Document>
            </Box>
          )}
        </Box>
        <TableComponent
          outputTable={getFetchedData(tabValue, pageTabValue)}
          save={save}
          setSave={setSave}
          handleOpenSaveModal={handleOpenSaveModal}
          tabValue={tabValue}
        />
        <SaveModal
          openSaveModal={openSaveModal}
          handleCloseSaveModal={handleCloseSaveModal}
          fetchedData={getFetchedData(tabValue, pageTabValue)}
        />
        {tabValue == 2 && <div>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 2,
              marginBottom: 2
            }}
          >
            <TextField
              label="Company Name"
              variant="outlined"
              placeholder='Enter Company Name'
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
            />
            <TextField
              label="Report FY"
              variant="outlined"
              placeholder='Enter Report FY'
              onChange={(e) => setReportFY(e.target.value)}
              value={reportFY}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadSoc}
          >
            Download SOC Report
          </Button>
        </div>}

      </Box>
    </>
  );
};

export default PDFToExcel;
