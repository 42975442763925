import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Modal,
  MenuItem,
  TextField
} from '@mui/material';
import {
  createTemplate,
  createDocument,
  getAllTemplates,
  getAllService,
  getAllDocuments,
  getDocumentsByTemplateId,
  deleteTemplateRequest,
  deleteDocumentRequest
} from '../../../constants/requests';
import { style } from '../common/styles';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../../../hooks/useLoading';
import { useNotifications } from '../../../hooks/useNotifications';
import Select from 'react-select';
import TemplateTable from '../common/TemplateTable';
import DeleteDialog from '../../Services/common/DeleteDialog';

const downloadStrategy = [
  {
    label: 'Download Single File For Each Application',
    value: 'SINGLE_APPLICATION'
  },
  {
    label: 'Download Combined File For All Applications',
    value: 'ALL_APPLICATIONS'
  }
];

const pagesOptions = [
  {
    label: 'UNDERSTANDING',
    value: 'UNDERSTANDING'
  },
  {
    label: 'RCM',
    value: 'RCM'
  },
  {
    label: 'DRL',
    value: 'DRL'
  },
  {
    label: 'TESTING',
    value: 'TESTING'
  },
  {
    label: 'CSA',
    value: 'CSA'
  },
  {
    label: 'MLP',
    value: 'MLP'
  },
  {
    label: 'AIT',
    value: 'AIT'
  }
];

const templateGroupOptions = [
  {
    label: 'RCM',
    value: 'RCM'
  },
  {
    label: 'GAP_TRACKER',
    value: 'GAP_TRACKER'
  },
  {
    label: 'ORG_TABLE',
    value: 'ORG_TABLE'
  },
  {
    label: 'POINT_OF_FOCUS',
    value: 'POINT_OF_FOCUS'
  },
  {
    label: 'TRUST_CRITERIA',
    value: 'TRUST_CRITERIA'
  },
  {
    label: 'SCF',
    value: 'SCF'
  },
  {
    label: 'SECTION3_HEADER',
    value: 'SECTION3_HEADER'
  },
  {
    label: 'CUEC_CSOC',
    value: 'CUEC_CSOC'
  }
];

const TemplateHome = () => {
  const navigate = useNavigate();
  const { request } = useLoading();
  const { showNotifications } = useNotifications();

  const [tempId, setTempId] = useState(null);
  const [tempName, setTempName] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [templateGroup, setTemplateGroup] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [deleteDocumentName, setDeleteDocumentName] = useState(null);
  const [dataFetchingStrategy, setDataFetchingStrategy] = useState(null);
  const [templateNameMapping, setTemplateNameMapping] = useState({});
  const [selected, setSelected] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [templateRows, setTemplateRows] = useState([]);
  const [documentRows, setDocumentRows] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = useState(false);
  const [openCreateDocumentModal, setOpenCreateDocumentModal] = useState(false);

  const handleCreateDocumentClose = () => {
    setTemplateId(null);
    setDocumentName(null);
    setDataFetchingStrategy(null);
    setOpenCreateDocumentModal(false);
  };

  const handleCreateClose = () => {
    setTemplateName(null);
    setTemplateGroup(null);
    setErrorMessage(null);
    setOpenCreateModal(false);
  };

  const handleCloseDeleteModal = () => {
    setTempId(null);
    setTempName(null);
    setOpenDeleteModal(false);
  };

  const handleCloseDeleteDocumentModal = () => {
    setDocumentId(null);
    setDeleteDocumentName(null);
    setOpenDeleteDocumentModal(false);
  };

  const handleDelete = async (idx) => {
    setTempId(templateRows[idx]['_id']);
    setTempName(templateRows[idx]['Template Name']);
    setOpenDeleteModal(true);
  };

  const handleDeleteDocument = async (idx) => {
    setDocumentId(documentRows[idx]['_id']);
    setDeleteDocumentName(documentRows[idx]['Document Name']);
    setOpenDeleteDocumentModal(true);
  };

  const handleUpdate = async (idx) => {
    navigate(`/templateEditor/createTemplate/${templateRows[idx]['_id']}`);
  };

  const handleUpdateDocument = async (idx) => {
    navigate(`/templateEditor/addDocument/${documentRows[idx]['_id']}`);
  };
  /**
   * @author Tejas Sharma
   * @description This function is used to get all the associated documents for a template
   * @param {Array} documents
   * @returns {Array} documents
   * Here we are using a for loop to iterate over the templates array and then we are calling the getDocumentsByTemplateId function
   * which is used to get all the documents associated with a template. We are then mapping over the response and creating a new array
   * of document names and then we are joining the array with a comma and storing it in the template object.
   *
   * All the documents of type testing are ignored because they are not to be shown in the table.
   * They are handled in the Template Creator Itself
   * */
  const getAssociatedDocuments = async (templates) => {
    try {
      for (const template of templates) {
        const res = await request(() =>
          getDocumentsByTemplateId(template['_id'])
        );
        if (res.data.statusCode === 200) {
          const docNames = res.data.body.map((item) => {
            if (item.documentType === 'testing') return '';
            else return item.documentName;
          });
          template['Associated Documents'] = docNames.join(', ');
        }
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadDocuments = async (templateMapping = templateNameMapping) => {
    try {
      const res = await request(() => getAllDocuments());
      if (res.data.statusCode === 200) {
        const documents = res.data.body.map((row) => {
          if (row.documentType === 'testing') return {};
          return {
            _id: row._id,
            'Document Name': row.documentName,
            'Template Name': templateMapping[row['templateId']]['name'],
            'Template Group': templateMapping[row.templateId]['group'],
            Action: 'Button'
          };
        });
        setDocumentRows(documents);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const getServiceList = async () => {
    try {
      const res = await request(() => getAllService());
      if (res.data.statusCode === 200) {
        const services = res.data.body.map((service) => {
          return {
            label: service.name,
            value: service._id
          };
        });
        setServiceList(services);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const loadApplication = async () => {
    try {
      const res = await request(() => getAllTemplates());
      if (res.data.statusCode === 200) {
        const templates = res.data.body.map((item) => {
          return {
            _id: item._id,
            'Template Name': item.templateName,
            'Template Group': item.templateGroup,
            'Associated Documents': '',
            Action: 'Button'
          };
        });
        await getAssociatedDocuments(templates);
        const mapping = {};
        res.data.body.forEach((item) => {
          return (mapping[item._id] = {
            name: item.templateName,
            group: item.templateGroup
          });
        });
        setTemplateNameMapping(mapping);
        setTemplateRows(templates);
        await loadDocuments(mapping);
        showNotifications('success', res.data.message, 5000);
      } else {
        showNotifications('error', res.data.message, 5000);
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleCreate = async () => {
    const formData = new FormData();
    formData.append('templateName', templateName);
    formData.append('templateGroup', templateGroup);
    if (serviceId !== null) formData.append('serviceId', serviceId);

    const pageList = selected.map((page) => {
      return {
        pageName: page.value,
        displayName: page.value
      };
    });

    formData.append('pages', JSON.stringify(pageList));
    formData.append('master', selectedFile);

    try {
      const res = await request(() => createTemplate(formData));
      if (
        res?.response?.data?.statusCode === 422 ||
        res?.response?.data?.statusCode === 500
      ) {
        setErrorMessage(res.response.data.body.errors[0]);
        showNotifications('error', res.response.data.message, 5000);
      } else if (res.data.statusCode === 201) {
        setTemplateRows([]);
        await loadApplication();
        showNotifications('success', res.data.message, 5000);
        handleCreateClose();
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  const handleAddDocument = async () => {
    const formData = new FormData();
    formData.append('documentName', documentName);
    formData.append('templateId', templateId);
    formData.append('document', selectedDocument);
    formData.append('dataFetchingStrategy', dataFetchingStrategy);
    try {
      const res = await request(() => createDocument(formData));
      if (
        res?.response?.data?.statusCode === 422 ||
        res?.response?.data?.statusCode === 500
      ) {
        showNotifications('error', res.response.data.message, 5000);
      } else if (res.data.statusCode === 201) {
        setDocumentRows([]);
        await loadApplication();
        showNotifications('success', res.data.message, 5000);
        handleCreateDocumentClose();
      }
    } catch (err) {
      showNotifications('error', err.toString(), 5000);
    }
  };

  useEffect(() => {
    loadApplication();
    getServiceList();
    return () => {
      setTemplateRows([]);
      setDocumentRows([]);
      setServiceList([]);
      setTemplateNameMapping({});
    };
  }, []);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Templates
        </Typography>
        <Button variant="contained" onClick={() => setOpenCreateModal(true)}>
          Create
        </Button>
      </Box>
      {templateRows.length > 0 && (
        <TemplateTable
          data={templateRows}
          deleteCB={handleDelete}
          updateCB={handleUpdate}
        />
      )}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '30px 0'
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          Documents
        </Typography>
        <Button
          variant="contained"
          onClick={() => setOpenCreateDocumentModal(true)}
        >
          Add
        </Button>
      </Box>
      {documentRows.length > 0 && (
        <TemplateTable
          data={documentRows}
          deleteCB={handleDeleteDocument}
          updateCB={handleUpdateDocument}
          type="document"
        />
      )}
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DeleteDialog
          entityName={tempName}
          deleteRequest={deleteTemplateRequest}
          entityId={tempId}
          onCloseCB={handleCloseDeleteModal}
          refreshPageContentCB={loadApplication}
        />
      </Modal>
      <Modal
        open={openDeleteDocumentModal}
        onClose={handleCloseDeleteDocumentModal}
      >
        <DeleteDialog
          entityName={deleteDocumentName}
          deleteRequest={deleteDocumentRequest}
          entityId={documentId}
          onCloseCB={handleCloseDeleteDocumentModal}
          refreshPageContentCB={loadApplication}
        />
      </Modal>
      <Modal
        open={openCreateModal}
        onClose={handleCreateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '50%', height: '95%', overflowX: 'scroll' }}
        >
          <Box
            style={{
              marginBottom: '25px',
              display: 'flex',
              gap: '20px',
              flexDirection: 'column'
            }}
          >
            <Typography
              fullWidth
              id="modal-modal-title"
              variant="h5"
              component="h2"
              style={{ marginBottom: '10px' }}
            >
              Create Template
            </Typography>
            {errorMessage && (
              <Typography
                variant="h5"
                color="red"
              >{`Error: ${errorMessage}`}</Typography>
            )}
            <TextField
              fullWidth
              value={templateName}
              name="templateName"
              label="Enter Template Name"
              onChange={(e) => setTemplateName(e.target.value)}
            />
            <TextField
              fullWidth
              select
              value={templateGroup}
              name="templateGroup"
              label="Select Template Group"
              onChange={(e) => setTemplateGroup(e.target.value)}
            >
              {templateGroupOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              select
              value={serviceId}
              name="serviceId"
              label="Select Service"
              onChange={(e) => setServiceId(e.target.value)}
            >
              {serviceList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {templateGroup === 'RCM' ? (
              <Select
                styles={{
                  option: (base) => ({
                    ...base,
                    border: `1px dotted black`,
                    height: '100%'
                  })
                }}
                isMulti
                options={pagesOptions}
                placeholder="Select Pages"
                value={selected}
                onChange={setSelected}
                name="pages"
              />
            ) : null}
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: '40px'
            }}
          >
            <Typography variant="h5">Upload Master</Typography>
            <TextField
              type="file"
              name="master"
              label="Upload Master"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '30px'
            }}
          >
            <Button
              color="error"
              variant="contained"
              onClick={handleCreateClose}
            >
              Close
            </Button>
            <Button variant="contained" onClick={handleCreate}>
              Create
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openCreateDocumentModal}
        onClose={handleCreateDocumentClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: '50%', height: '80%', overflowX: 'scroll' }}
        >
          <Box
            style={{
              marginBottom: '25px',
              display: 'flex',
              gap: '20px',
              flexDirection: 'column'
            }}
          >
            <Typography
              fullWidth
              id="modal-modal-title"
              variant="h5"
              component="h2"
              style={{ marginBottom: '30px' }}
            >
              Add Document
            </Typography>
            <TextField
              fullWidth
              value={documentName}
              name="documentName"
              label="Enter Document Name"
              onChange={(e) => setDocumentName(e.target.value)}
            />
            <TextField
              fullWidth
              select
              value={templateId}
              name="templateId"
              label="Select Template Name"
              onChange={(e) => setTemplateId(e.target.value)}
            >
              {Object.keys(templateNameMapping).map((option) => (
                <MenuItem key={option} value={option}>
                  {templateNameMapping[option]['name']}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              select
              value={dataFetchingStrategy}
              name="dataFetchingStrategy"
              label="Select Download Option"
              onChange={(e) => setDataFetchingStrategy(e.target.value)}
            >
              {downloadStrategy.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: '40px'
            }}
          >
            <Typography variant="h5">Upload Document</Typography>
            <TextField
              type="file"
              name="document"
              label="Upload Document"
              onChange={(e) => setSelectedDocument(e.target.files[0])}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '30px'
            }}
          >
            <Button
              color="error"
              variant="contained"
              onClick={handleCreateDocumentClose}
            >
              Close
            </Button>
            <Button variant="contained" onClick={handleAddDocument}>
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TemplateHome;
