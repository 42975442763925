import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TablePagination,
  TableContainer,
  tableCellClasses
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import GetTableCell from './GetTableCell';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: '0px',
  paddingBottom: '0px',
  paddingRight: '5px',
  paddingLeft: '5px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  paddingTop: '0px',
  paddingBottom: '0px',
  margin: '0px',
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const stickyStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 800,
  minWidth: '300px',
  minHeight: '0px'
};

const normalStyle = {
  minWidth: '300px',
  minHeight: '0px'
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomTable({
  fieldsMeta,
  tableMeta,
  rows,
  stickyColumnName,
  handleChangeCB,
  tabName,
  topStyle,
  deleteCB,
  revertCB,
  miscellaneous,
  generateButtonStatus
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {rows.length > 0 &&
                Object.keys(tableMeta).map((tableHeader) => (
                  <StyledTableCell
                    key={`Header-${uuidv4()}`}
                    style={
                      tableHeader === stickyColumnName
                        ? stickyStyle
                        : normalStyle
                    }
                    align="left"
                  >
                    {tableMeta[tableHeader]['displayName'] === undefined
                      ? tableHeader
                      : tableMeta[tableHeader]['displayName']}
                  </StyledTableCell>
                ))}
              {rows.length > 0 && deleteCB && (
                <StyledTableCell
                  key={`Delete-Header-${uuidv4()}`}
                  style={{ minWidth: '100px' }}
                  align="left"
                >
                  Delete Action
                </StyledTableCell>
              )}
              {rows.length > 0 && revertCB && (
                <StyledTableCell
                  key={`Revert-Header-${uuidv4()}`}
                  style={{ minWidth: '100px' }}
                  align="left"
                >
                  Revert Action
                </StyledTableCell>
              )}
              {rows.length > 0 && miscellaneous && (
                <StyledTableCell
                  key={`Generate-Header-${uuidv4()}`}
                  style={{ minWidth: '100px' }}
                  align="left"
                >
                  Generate Action
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, idx) => (
              <StyledTableRow key={`CustomTable-row-${uuidv4()}`}>
                {Object.keys(tableMeta).map((key) => {
                  return (
                    <StyledTableCell
                      key={`CustomTable-cellData-${uuidv4()}`}
                      align="left"
                      style={
                        key === stickyColumnName ? stickyStyle : normalStyle
                      }
                      sx={{
                        top: topStyle,
                        background:
                          key === stickyColumnName ? '#F5F5F5' : '#fff'
                      }}
                    >
                      <GetTableCell
                        key={`CustomTable-cell-${uuidv4()}`}
                        rowNumber={rowsPerPage * page + idx}
                        fieldName={key}
                        fieldType={fieldsMeta[key].fieldType}
                        value={row[key]}
                        onChangeCB={(rowNumber, fieldName, cellValue) =>
                          handleChangeCB(
                            rowNumber,
                            fieldName,
                            cellValue,
                            tabName
                          )
                        }
                        isEditable={tableMeta[key].isEditable}
                        options={fieldsMeta[key].options}
                      />
                    </StyledTableCell>
                  );
                })}
                {rows.length > 0 && deleteCB && (
                  <StyledTableCell
                    key={`Delete-Button-${uuidv4()}`}
                    style={{ minWidth: '50px' }}
                    align="left"
                  >
                    <Button
                      index={rowsPerPage * page + idx + 1}
                      color="error"
                      variant="contained"
                      onClick={() => deleteCB(rowsPerPage * page + idx)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                )}
                {rows.length > 0 && revertCB && (
                  <StyledTableCell
                    key={`Revert-Button-${uuidv4()}`}
                    style={{ minWidth: '50px' }}
                    align="left"
                  >
                    <Button
                      color="warning"
                      variant="contained"
                      onClick={() =>
                        revertCB(row, rowsPerPage * page + idx, tabName)
                      }
                    >
                      Revert
                    </Button>
                  </StyledTableCell>
                )}
                {rows.length > 0 && miscellaneous && (
                  <StyledTableCell>
                    <Button
                      index={rowsPerPage * page + idx + 1}
                      variant="contained"
                      onClick={() => {
                        miscellaneous(
                          { keywordObject: row },
                          rowsPerPage * page + idx
                        );
                      }}
                      disabled={generateButtonStatus[rowsPerPage * page + idx]}
                    >
                      Generate
                    </Button>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

CustomTable.propTypes = {
  fieldsMeta: PropTypes.objectOf(PropTypes.object),
  tableMeta: PropTypes.objectOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  stickyColumnName: PropTypes.string,
  handleChangeCB: PropTypes.func,
  tabName: PropTypes.string
};
